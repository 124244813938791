:where(h1, h2, h3, h4, h5, h6):has(
    .hlx-typography-brand-heading,
    .hlx-typography-brand-content,
    .hlx-typography-brand-section,
    .hlx-typography-page-heading,
    .hlx-typography-section-heading,
    .hlx-typography-list-heading
  ) {
  margin: 0;
  font-size: 0;
}

:root {
  --hlx-color-background-primary: #fff;
  --hlx-color-background-secondary: #f8f8f8;
  --hlx-color-background-tertiary: #ebebeb;
  --hlx-color-background-quartenary: #dadce0;
  --hlx-color-background-disabled: #bebebe;
  --hlx-color-background-inverted-primary: #212326;
  --hlx-color-background-inverted-secondary: #4d4c4d;
  --hlx-color-background-inverted-tertiary: #717171;
  --hlx-color-background-compliance-light: #e7f7f0;
  --hlx-color-background-compliance: #0b663d;
  --hlx-color-background-compliance-hover: #084428;
  --hlx-color-background-compliance-active: #052e1b;
  --hlx-color-background-selected: #d0eee0;
  --hlx-color-background-selected-hover: #a1ddc1;
  --hlx-color-background-selected-active: #87d2b0;
  --hlx-color-background-info: #e8f3ff;
  --hlx-color-background-info-hover: #a2d0fe;
  --hlx-color-background-warning: #fff6e9;
  --hlx-color-background-warning-hover: #ffdeb0;
  --hlx-color-background-danger-light: #feeeec;
  --hlx-color-background-danger: #9e2515;
  --hlx-color-background-danger-hover: #7c1d12;
  --hlx-color-background-danger-active: #611510;
  --hlx-color-foreground-primary: #353535;
  --hlx-color-foreground-secondary: #717171;
  --hlx-color-foreground-disabled: #bebebe;
  --hlx-color-foreground-compliance: #13aa65;
  --hlx-color-foreground-compliance-secondary: #0b663d;
  --hlx-color-foreground-compliance-tertiary: #084428;
  --hlx-color-foreground-info: #1789fc;
  --hlx-color-foreground-info-secondary: #0e5297;
  --hlx-color-foreground-warning: #f09e29;
  --hlx-color-foreground-warning-secondary: #925600;
  --hlx-color-foreground-danger: #fd5139;
  --hlx-color-foreground-danger-secondary: #9e2515;
  --hlx-color-foreground-inverted-primary: #fff;
  --hlx-color-border-primary: #dadce0;
  --hlx-color-border-secondary: #717171;
  --hlx-color-border-tertiary: #353535;
  --hlx-color-border-focus: #0b663d;
  --hlx-color-border-info: #0e5297;
  --hlx-color-border-warning: #925600;
  --hlx-color-border-danger: #9e2515;
  --hlx-color-border-disabled: #ebebeb;
  --hlx-spacing-x1: 4px;
  --hlx-spacing-x2: 8px;
  --hlx-spacing-x3: 12px;
  --hlx-spacing-x4: 16px;
  --hlx-spacing-x5: 20px;
  --hlx-spacing-x6: 24px;
  --hlx-spacing-x7: 28px;
  --hlx-spacing-x8: 32px;
  --hlx-spacing-x9: 36px;
  --hlx-spacing-x10: 40px;
  --hlx-spacing-x11: 44px;
  --hlx-spacing-x12: 48px;
  --hlx-elevation-light: 0px 2px 16px #0000001f;
  --hlx-elevation-medium: 0px 2px 8px #0003;
  --hlx-layer-backdrop: 0;
  --hlx-layer-base: 10;
  --hlx-layer-modal: 20;
  --hlx-layer-popover: 30;
  --hlx-typography-font-family-headline: 'Honey Regular', serif;
  --hlx-typography-font-family-content: 'PostGrotesk', sans-serif;
  --hlx-typography-font-weight-headline-regular: 400;
  --hlx-typography-font-weight-content-regular: 400;
  --hlx-typography-font-weight-content-medium: 500;
  --hlx-typography-line-height-base-3: 16px;
  --hlx-typography-line-height-base-4: 18px;
  --hlx-typography-line-height-base-6: 22px;
  --hlx-typography-line-height-base-7: 24px;
  --hlx-typography-line-height-base-8: 28px;
  --hlx-typography-line-height-base-9: 32px;
  --hlx-typography-line-height-base-10: 36px;
  --hlx-typography-line-height-base-14: 52px;
  --hlx-typography-line-height-base-17: 64px;
  --hlx-typography-line-height-base-10: 36px;
  --hlx-typography-font-size-base-4: 13px;
  --hlx-typography-font-size-base-5: 14px;
  --hlx-typography-font-size-base-7: 16px;
  --hlx-typography-font-size-base-9: 18px;
  --hlx-typography-font-size-base-11: 21px;
  --hlx-typography-font-size-base-13: 26px;
  --hlx-typography-font-size-base-14: 28px;
  --hlx-typography-font-size-base-15: 32px;
  --hlx-typography-font-size-base-18: 44px;
  --hlx-typography-font-size-base-20: 56px;
}

@keyframes slide-in {
  from {
    transform: var(--slide-from);
  }

  to {
    transform: var(--slide-to);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

html {
  box-sizing: border-box;
}

*,
:before,
:after {
  box-sizing: inherit;
}

@font-face {
  font-family: PostGrotesk;
  src:
    url('./fonts/PostGrotesk-Book.woff2') format('woff2'),
    url('./fonts/PostGrotesk-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: PostGrotesk;
  src:
    url('./fonts/PostGrotesk-Medium.woff2') format('woff2'),
    url('./fonts/PostGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: PostGrotesk;
  src:
    url('./fonts/PostGrotesk-Medium.woff2') format('woff2'),
    url('./fonts/PostGrotesk-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Honey Regular;
  src:
    url('./fonts/VCHoney-Regular.woff2') format('woff2'),
    url('./fonts/VCHoney-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.hlx-avatar {
  aspect-ratio: 1;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  place-items: center;
  display: grid;
}

.hlx-avatar[data-hlx-size='small'] {
  --size: 32px;
}

.hlx-avatar[data-hlx-size='small'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-avatar[data-hlx-size='small'] {
    font-size: 15px;
  }
}

.hlx-avatar[data-hlx-size='medium'] {
  --size: 40px;
}

.hlx-avatar[data-hlx-size='medium'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-avatar[data-hlx-size='medium'] {
    font-size: 17px;
  }
}

.hlx-avatar[data-hlx-size='large'] {
  --size: 56px;
}

.hlx-avatar[data-hlx-size='large'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-11);
  line-height: var(--hlx-typography-line-height-base-8);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.01em;
}

.hlx-badge {
  border-radius: 2px;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 2px 4px;
  display: flex;
}

.hlx-badge .hlx-badge-icon {
  color: var(--icon-color);
  aspect-ratio: 1;
  align-self: flex-start;
  min-width: 12px;
  max-height: 16px;
  display: block;
}

.hlx-badge .hlx-badge-content {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-badge[data-hlx-variant='neutral'] {
  background-color: var(--hlx-color-background-tertiary);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-primary);
}

.hlx-badge[data-hlx-variant='info'] {
  background-color: var(--hlx-color-background-info);
  color: var(--hlx-color-foreground-info-secondary);
  --icon-color: var(--hlx-color-foreground-info);
}

.hlx-badge[data-hlx-variant='positive'] {
  background-color: var(--hlx-color-background-selected);
  color: var(--hlx-color-foreground-compliance-secondary);
  --icon-color: var(--hlx-color-foreground-compliance);
}

.hlx-badge[data-hlx-variant='warning'] {
  background-color: var(--hlx-color-background-warning);
  color: var(--hlx-color-foreground-warning-secondary);
  --icon-color: var(--hlx-color-foreground-warning);
}

.hlx-badge[data-hlx-variant='negative'] {
  background-color: var(--hlx-color-background-danger-light);
  color: var(--hlx-color-foreground-danger-secondary);
  --icon-color: var(--hlx-color-foreground-danger);
}

.hlx-banner {
  padding: var(--hlx-spacing-x3) var(--hlx-spacing-x4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.hlx-banner .hlx-banner-icon {
  color: var(--icon-color);
  margin-right: var(--hlx-spacing-x3);
  flex-shrink: 0;
}

.hlx-banner .hlx-banner-content {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-banner .hlx-banner-content {
    font-size: 17px;
  }
}

.hlx-banner .hlx-banner-adornment {
  margin-left: var(--hlx-spacing-x6);
}

.hlx-banner[data-hlx-variant='neutral'] {
  background-color: var(--hlx-color-background-tertiary);
  --icon-color: var(--hlx-color-foreground-primary);
}

.hlx-banner[data-hlx-variant='info'] {
  background-color: var(--hlx-color-background-info);
  --icon-color: var(--hlx-color-foreground-info);
}

.hlx-banner[data-hlx-variant='success'] {
  background-color: var(--hlx-color-background-selected);
  --icon-color: var(--hlx-color-foreground-compliance);
}

.hlx-banner[data-hlx-variant='warning'] {
  background-color: var(--hlx-color-background-warning);
  --icon-color: var(--hlx-color-foreground-warning);
}

.hlx-banner[data-hlx-variant='error'] {
  background-color: var(--hlx-color-background-danger-light);
  --icon-color: var(--hlx-color-foreground-danger);
}

.hlx-breadcrumbs {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.hlx-breadcrumbs {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-breadcrumbs {
    font-size: 15px;
  }
}

.hlx-breadcrumb {
  align-items: center;
  display: flex;
}

.hlx-breadcrumb:last-child .hlx-breadcrumb-separator {
  display: none;
}

.hlx-breadcrumb .hlx-link {
  text-decoration: none !important;
}

.hlx-button {
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  outline: none;
}

.hlx-button[data-hlx-size='medium'] {
  padding: 6px 12px;
}

.hlx-button[data-hlx-size='medium'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-button[data-hlx-size='medium'] {
    padding: 10px 15px;
    font-size: 15px;
  }
}

.hlx-button[data-hlx-size='large'] {
  padding: 6px 16px;
}

.hlx-button[data-hlx-size='large'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-button[data-hlx-size='large'] {
    padding: 10px 15px;
    font-size: 17px;
  }
}

.hlx-button[data-hlx-variant='brand'] {
  --border-color: transparent;
  --hover-bg-color: var(--hlx-color-background-compliance-hover);
  --disabled-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-compliance-active);
  --active-text-color: var(--hlx-color-foreground-inverted-primary);
  background-color: var(--hlx-color-background-compliance);
  border: 1px solid var(--border-color);
  color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-button[data-hlx-variant='primary'] {
  --border-color: transparent;
  --hover-bg-color: var(--hlx-color-background-inverted-secondary);
  --disabled-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-inverted-tertiary);
  --active-text-color: var(--hlx-color-foreground-inverted-primary);
  background-color: var(--hlx-color-background-inverted-primary);
  border: 1px solid var(--border-color);
  color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-button[data-hlx-variant='secondary'] {
  --border-color: var(--hlx-color-border-primary);
  --disabled-bg-color: var(--hlx-color-background-primary);
  --hover-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-quartenary);
  --active-text-color: var(--hlx-color-foreground-primary);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  color: var(--hlx-color-foreground-primary);
}

.hlx-button[data-hlx-variant='secondary'][data-hlx-disabled='true'] {
  color: var(--hlx-color-foreground-disabled);
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-button[data-hlx-variant='link'] {
  --disabled-bg-color: transparent;
  --hover-bg-color: transparent;
  --hover-text-color: var(--hlx-color-foreground-primary);
  --active-bg-color: transparent;
  --active-text-color: var(--hlx-color-foreground-secondary);
  color: var(--hlx-color-foreground-primary);
  text-decoration: underline;
  text-decoration-color: var(--hlx-color-background-disabled);
  text-underline-offset: 5px;
  background-color: #0000;
  border-radius: 4px;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .hlx-button[data-hlx-variant='link'] {
    padding: 0;
  }
}

.hlx-link-button {
  white-space: nowrap;
  text-align: center;
}

.hlx-icon-button {
  white-space: nowrap;
  aspect-ratio: 1;
  width: var(--size);
  height: var(--size);
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hlx-icon-button[data-hlx-size='small'][data-hlx-variant='default'] {
  --size: 32px;
}

.hlx-icon-button[data-hlx-size='small'][data-hlx-variant='transparent'] {
  --size: 20px;
}

@media screen and (max-width: 768px) {
  .hlx-icon-button[data-hlx-size='small'][data-hlx-variant='transparent'] {
    --size: 24px;
  }
}

.hlx-icon-button[data-hlx-size='small'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-icon-button[data-hlx-size='small'] {
    font-size: 15px;
  }
}

.hlx-icon-button[data-hlx-size='medium'] {
  --size: 32px;
}

.hlx-icon-button[data-hlx-size='medium'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-icon-button[data-hlx-size='medium'] {
    --size: 44px;
    font-size: 15px;
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-7);
    line-height: var(--hlx-typography-line-height-base-6);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-icon-button[data-hlx-size='medium'] {
      font-size: 17px;
    }
  }
}

.hlx-icon-button[data-hlx-size='large'] {
  --size: 40px;
}

.hlx-icon-button[data-hlx-size='large'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-icon-button[data-hlx-size='large'] {
    --size: 44px;
    font-size: 17px;
  }
}

.hlx-icon-button[data-hlx-variant='default'] {
  --border-color: var(--hlx-color-border-primary);
  --disabled-bg-color: var(--hlx-color-background-tertiary);
  --hover-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-quartenary);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  color: var(--hlx-color-foreground-primary);
}

.hlx-icon-button[data-hlx-variant='transparent'] {
  --border-color: transparent;
  --disabled-bg-color: transparent;
  --hover-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-quartenary);
  color: var(--hlx-color-foreground-primary);
  background-color: #0000;
  padding: 0;
}

.hlx-tooltip-icon-button {
  background-color: #0000;
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  display: grid;
}

:is(
    .hlx-button,
    .hlx-icon-button,
    .hlx-tooltip-icon-button
  )[data-hlx-focused='true'] {
  outline-offset: 2px;
  background-color: var(--hover-bg-color);
  text-decoration-color: var(--hover-text-color);
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

:is(
    .hlx-button,
    .hlx-icon-button,
    .hlx-tooltip-icon-button
  )[data-hlx-hovered='true'] {
  cursor: pointer;
  background-color: var(--hover-bg-color);
  text-decoration-color: var(--hover-text-color);
}

:is(
    .hlx-button,
    .hlx-icon-button,
    .hlx-tooltip-icon-button
  )[data-hlx-pressed='true'] {
  background-color: var(--active-bg-color);
  color: var(--active-text-color);
}

:is(
    .hlx-button,
    .hlx-icon-button,
    .hlx-tooltip-icon-button
  )[data-hlx-disabled='true'] {
  --border-color: var(--hlx-color-border-primary);
  color: var(--hlx-color-foreground-disabled);
  text-decoration-color: var(--hlx-color-foreground-disabled);
  background-color: var(--disabled-bg-color);
}

.hlx-toggle-button {
  white-space: nowrap;
  --border-color: var(--hlx-color-border-primary);
  --bg-color: var(--hlx-color-background-primary);
  --text-color: var(--hlx-color-foreground-primary);
  background-color: var(--bg-color);
  border: none;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  outline: none;
  outline: 0px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.hlx-toggle-button[data-hlx-size='medium'] {
  padding: 6px 12px;
}

.hlx-toggle-button[data-hlx-size='medium'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-toggle-button[data-hlx-size='medium'] {
    padding: 10px 15px;
    font-size: 15px;
  }
}

.hlx-toggle-button[data-hlx-size='large'] {
  padding: 6px 16px;
}

.hlx-toggle-button[data-hlx-size='large'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-toggle-button[data-hlx-size='large'] {
    padding: 10px 15px;
    font-size: 17px;
  }
}

.hlx-toggle-button[data-focus-visible] {
  outline-width: 2px;
}

.hlx-toggle-button[data-hovered] {
  cursor: pointer;
  --bg-color: var(--hlx-color-background-tertiary);
}

.hlx-toggle-button[data-pressed] {
  --bg-color: var(--hlx-color-background-quartenary);
}

.hlx-toggle-button[data-selected='true'] {
  --bg-color: var(--hlx-color-background-selected);
  --border-color: var(--hlx-color-border-focus);
  --text-color: var(--hlx-color-foreground-compliance-tertiary);
}

.hlx-toggle-button[data-selected][data-hovered],
.hlx-toggle-button[data-selected][data-focused] {
  --bg-color: var(--hlx-color-background-selected-hover);
}

.hlx-toggle-button[data-selected][data-pressed] {
  --bg-color: var(--hlx-color-background-selected-active);
}

.hlx-toggle-button[data-disabled] {
  --border-color: var(--hlx-color-border-primary);
  --text-color: var(--hlx-color-foreground-disabled);
  --bg-color: var(--hlx-color-background-tertiary);
}

.hlx-toggle-button[data-disabled][data-selected] {
  --bg-color: var(--hlx-color-background-selected);
  --text-color: #0844284d;
  --border-color: #08442826;
}

.hlx-toggle-button-group {
  gap: var(--hlx-spacing-x2);
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  display: flex;
}

.hlx-split-button {
  border-radius: 4px;
  align-items: stretch;
  display: flex;
}

.hlx-split-button .hlx-button {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.hlx-split-button .hlx-button[data-hlx-focused] {
  z-index: 1;
}

.hlx-split-button .hlx-split-button-trigger {
  appearance: none;
  cursor: pointer;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: none;
  border: 1px solid var(--border-color);
  border-left: 1px solid var(--hlx-color-background-tertiary);
  outline: 0px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.hlx-split-button .hlx-split-button-trigger[data-focus-visible] {
  outline-width: 2px;
}

.hlx-split-button[data-hlx-size='medium'] .hlx-split-button-trigger {
  width: 32px;
}

.hlx-split-button[data-hlx-size='large'] .hlx-split-button-trigger {
  width: 36px;
}

.hlx-split-button[data-hlx-variant='brand'] {
  --border-color: var(--hlx-color-background-compliance);
  --bg-color: var(--hlx-color-background-compliance);
  --text-color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-split-button[data-hlx-variant='brand']
  .hlx-split-button-trigger[data-hovered],
.hlx-split-button[data-hlx-variant='brand']
  .hlx-split-button-trigger[data-focused] {
  --bg-color: var(--hlx-color-background-compliance-hover);
  --border-color: var(--hlx-color-background-compliance-hover);
}

.hlx-split-button[data-hlx-variant='brand']
  .hlx-split-button-trigger[aria-expanded='true'] {
  --bg-color: var(--hlx-color-background-compliance-active);
  --border-color: var(--hlx-color-background-compliance-active);
}

.hlx-split-button[data-hlx-variant='primary'] {
  --border-color: var(--hlx-color-background-inverted-primary);
  --bg-color: var(--hlx-color-background-inverted-primary);
  --text-color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-split-button[data-hlx-variant='primary']
  .hlx-split-button-trigger[data-hovered],
.hlx-split-button[data-hlx-variant='primary']
  .hlx-split-button-trigger[data-focused] {
  --bg-color: var(--hlx-color-background-inverted-secondary);
  --border-color: var(--hlx-color-background-inverted-secondary);
}

.hlx-split-button[data-hlx-variant='primary']
  .hlx-split-button-trigger[aria-expanded='true'] {
  --bg-color: var(--hlx-color-background-inverted-tertiary);
  --border-color: var(--hlx-color-background-inverted-tertiary);
}

.hlx-split-button[data-hlx-variant='secondary'] {
  --border-color: var(--hlx-color-border-primary);
  --bg-color: var(--hlx-color-background-primary);
  --text-color: var(--hlx-color-foreground-primary);
}

.hlx-split-button[data-hlx-variant='secondary'] .hlx-split-button-trigger {
  border-left-color: var(--hlx-color-border-primary);
}

.hlx-split-button[data-hlx-variant='secondary']
  .hlx-split-button-trigger[data-hovered],
.hlx-split-button[data-hlx-variant='secondary']
  .hlx-split-button-trigger[data-focused] {
  --bg-color: var(--hlx-color-background-tertiary);
}

.hlx-split-button[data-hlx-variant='secondary']
  .hlx-split-button-trigger[aria-expanded='true'] {
  --bg-color: var(--hlx-color-background-quartenary);
  --border-color: var(--hlx-color-background-quartenary);
}

.hlx-split-button-menu .hlx-menu-item[data-focused] {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-card {
  border-radius: var(--hlx-spacing-x1);
  gap: var(--hlx-spacing-x3);
  padding: var(--hlx-spacing-x3) var(--hlx-spacing-x4);
  background-color: var(--hlx-color-background-primary);
  color: var(--hlx-color-foreground-primary);
  border-color: var(--hlx-color-border-primary);
  border-style: solid;
  border-width: 1px;
  display: flex;
}

.hlx-chip-group-root {
  pointer-events: none;
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}

.hlx-chip-list {
  display: contents;
}

.hlx-chip-root {
  background-color: var(--hlx-color-background-tertiary);
  cursor: default;
  pointer-events: all;
  --outline-color: var(--hlx-color-border-focus);
  outline-width: 0;
  outline-style: solid;
  outline-color: var(--outline-color);
  color: var(--hlx-color-foreground-primary);
  border-radius: 2px;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  display: flex;
}

.hlx-chip-root.disabled {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-chip-root.focus-ring {
  outline-width: 2px;
}

.hlx-chip-label {
  outline: none;
}

.hlx-chip-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-chip-label {
    font-size: 15px;
  }
}

.hlx-chip-action {
  background-color: #0000;
  border: none;
  border-radius: 2px;
  outline: none;
  place-items: center;
  width: 18px;
  height: 18px;
  padding: 0;
  display: grid;
}

.hlx-chip-root.focus-ring .hlx-chip-action,
.hlx-chip-action:not(.disabled).hovered {
  background-color: var(--hlx-color-background-disabled);
}

.hlx-chip-group .hlx-chip-group-end-slot-placeholder {
  flex: 1;
  min-width: 5ch;
  height: 24px;
  display: none;
}

.hlx-divider {
  background-color: var(--hlx-color-border-primary);
  color: var(--hlx-color-border-primary);
  border: 0;
  align-self: stretch;
  height: 1px;
  display: block;
  overflow: visible;
}

.hlx-glossary-term-tray-content {
  padding: var(--hlx-spacing-x5);
}

.hlx-glossary-term-tray-content {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-glossary-term-tray-content {
    font-size: 17px;
  }
}

.hlx-glossary-term-backdrop {
  background: var(--hlx-color-background-inverted-primary);
  opacity: 0.5;
  z-index: 1400;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.hlx-glossary-term-button {
  cursor: default;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-underline-offset: 0.25em;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  text-decoration: underline 2px dotted;
}

.hlx-glossary-term-button.focus-ring {
  outline-offset: 2px;
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

.hlx-tooltip {
  background-color: var(--hlx-color-background-inverted-primary);
  z-index: var(--hlx-layer-popover);
  border-radius: 2px;
  max-width: 250px;
  padding: 4px 8px;
  display: inline-flex;
  position: relative;
}

.hlx-tooltip .hlx-tooltip-label {
  color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-tooltip .hlx-tooltip-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-tooltip .hlx-tooltip-arrow {
  --tooltip-tip-width: 8px;
  border-width: var(--tooltip-tip-width);
  border-style: solid;
  border-bottom-color: #0000;
  border-left-color: #0000;
  border-right-color: #0000;
  border-top-color: var(--hlx-color-background-inverted-primary);
  margin-left: calc(var(--tooltip-tip-width) * -1);
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
}

.hlx-tooltip[data-placement='bottom'] {
  margin-top: 8px;
}

.hlx-tooltip[data-placement='top'] {
  margin-bottom: 8px;
}

.hlx-tooltip[data-placement='left'] {
  margin-right: 8px;
}

.hlx-tooltip[data-placement='right'] {
  margin-left: 8px;
}

.hlx-tooltip[data-placement='bottom'] .hlx-tooltip-arrow {
  bottom: 100%;
  transform: rotate(-180deg);
}

.hlx-tooltip[data-placement='top'] .hlx-tooltip-arrow {
  top: 100%;
}

.hlx-tooltip[data-placement='left'] .hlx-tooltip-arrow {
  left: calc(100% + var(--tooltip-tip-width));
  transform: translate(0, -50%) rotate(-90deg);
}

.hlx-tooltip[data-placement='right'] .hlx-tooltip-arrow {
  left: calc(var(--tooltip-tip-width) * -1);
  transform: translate(0, -50%) rotate(90deg);
}

.hlx-guidance-card {
  border-radius: 4px;
  gap: 12px;
  padding: 12px 16px;
  display: flex;
}

.hlx-guidance-card .hlx-guidance-card-icon {
  color: var(--icon-color);
  aspect-ratio: 1;
  min-width: 20px;
  display: flex;
}

.hlx-guidance-card .hlx-guidance-card-title {
  display: block;
}

.hlx-guidance-card .hlx-guidance-card-title {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-guidance-card .hlx-guidance-card-content {
  align-items: flex-start;
  gap: var(--hlx-spacing-x3);
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.hlx-guidance-card .hlx-guidance-card-layout {
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.hlx-guidance-card[data-hlx-layout='horizontal'] {
  align-items: center;
}

.hlx-guidance-card[data-hlx-layout='horizontal'] .hlx-guidance-card-content {
  flex-direction: row;
}

.hlx-guidance-card[data-hlx-layout='vertical'] {
  align-items: flex-start;
}

.hlx-guidance-card[data-hlx-layout='vertical'] .hlx-guidance-card-content {
  flex-direction: column;
}

.hlx-guidance-card[data-hlx-variant='info'] {
  background-color: var(--hlx-color-background-info);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-info);
}

.hlx-guidance-card[data-hlx-variant='warning'] {
  background-color: var(--hlx-color-background-warning);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-warning);
}

.hlx-guidance-card[data-hlx-variant='error'] {
  background-color: var(--hlx-color-background-danger-light);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-danger);
}

.hlx-guidance-card[data-hlx-variant='neutral'] {
  background-color: var(--hlx-color-background-tertiary);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-primary);
}

.hlx-guidance-card[data-hlx-variant='positive'] {
  background-color: var(--hlx-color-background-selected);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-compliance);
}

.hlx-guidance-card[data-hlx-variant='insight'] {
  background-color: var(--hlx-color-background-info);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-info);
}

.hlx-guidance-card[data-hlx-variant='compliance'] {
  background-color: var(--hlx-color-background-selected);
  color: var(--hlx-color-foreground-primary);
  --icon-color: var(--hlx-color-foreground-compliance);
}

.hlx-collapsible-disclosure {
  gap: var(--hlx-spacing-x3);
  flex-direction: column;
  display: flex;
}

.hlx-collapsible-disclosure .hlx-collapsible-disclosure-title {
  user-select: none;
  color: var(--hlx-color-foreground-primary);
  text-decoration: underline;
  text-decoration-color: var(--hlx-color-background-disabled);
  text-underline-offset: 4px;
  align-items: center;
  gap: var(--hlx-spacing-x1);
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
}

.hlx-collapsible-disclosure .hlx-collapsible-disclosure-title:hover {
  cursor: pointer;
  text-decoration-color: var(--hlx-color-foreground-primary);
}

.hlx-collapsible-disclosure .hlx-collapsible-disclosure-title.focus-ring {
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  text-decoration-color: var(--hlx-color-foreground-primary);
  border-radius: 4px;
}

.hlx-collapsible-disclosure
  .hlx-collapsible-disclosure-title
  .hlx-collapsible-disclosure-icon {
  max-height: var(--hlx-spacing-x4);
  aspect-ratio: 1;
  align-items: center;
  display: inline-flex;
}

.hlx-collapsible-disclosure .hlx-collapsible-disclosure-label {
  margin-right: 8px;
}

ol:has(.hlx-numbered-list-item) {
  color: var(--hlx-color-foreground-primary);
  gap: var(--hlx-spacing-x3);
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  display: flex;
}

ol:has(.hlx-numbered-list-item) {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  ol:has(.hlx-numbered-list-item) {
    font-size: 17px;
  }
}

.hlx-numbered-list-item {
  --size: 20px;
  flex-direction: column;
  margin-inline-start: calc(var(--size) + var(--hlx-spacing-x3));
  display: flex;
  position: relative;
}

.hlx-numbered-list-item .hlx-numbered-list-item-bullet {
  height: var(--size);
  width: var(--size);
  left: calc(-1 * (var(--size) + var(--hlx-spacing-x3)));
  position: absolute;
}

.hlx-numbered-list-item .hlx-numbered-list-item-bullet path:not(:first-child) {
  fill: #fff;
}

.hlx-list-row {
  color: var(--hlx-color-foreground-primary);
  border-color: var(--hlx-color-border-primary);
  padding: var(--hlx-spacing-x4);
  border-top-style: solid;
  border-top-width: 0;
  list-style-type: none;
}

.hlx-list-row {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-list-row {
    font-size: 17px;
  }
}

.hlx-list-row + .hlx-list-row {
  border-top-width: 1px;
}

.hlx-link {
  --text-color: var(--hlx-color-foreground-primary);
  color: var(--text-color) !important;
}

.hlx-link {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-underline-offset: 2px;
  text-decoration: underline !important;
}

.hlx-link:visited {
  color: var(--text-color);
}

.hlx-link.focus-ring {
  outline-offset: 2px;
  border-radius: 4px;
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

.hlx-link[data-hlx-hovered='true'] {
  cursor: pointer;
  --text-color: var(--hlx-color-foreground-secondary);
}

.hlx-link[data-hlx-disabled='true'] {
  cursor: auto;
  --text-color: var(--hlx-color-foreground-disabled);
}

.hlx-table {
  color: var(--hlx-color-foreground-primary);
  border-collapse: collapse;
}

.hlx-table {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-table {
    font-size: 17px;
  }
}

.hlx-table th {
  padding: var(--hlx-spacing-x5) var(--hlx-spacing-x4) var(--hlx-spacing-x2)
    var(--hlx-spacing-x4);
  vertical-align: bottom;
  outline: none;
}

.hlx-table th {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-table th {
    font-size: 15px;
  }
}

.hlx-table th[data-focused='true'] {
  outline: 2px solid var(--hlx-color-border-focus);
}

.hlx-table th[data-sortable='true'] > span {
  align-items: center;
  gap: var(--hlx-spacing-x1);
  grid-template-columns: min-content 1fr;
  display: grid;
}

.hlx-table tr {
  outline: none;
}

.hlx-table tr[data-pressable='true'] {
  cursor: default;
}

.hlx-table tr[data-focused='true'] {
  outline: 2px solid var(--hlx-color-border-focus);
}

.hlx-table tr[data-hovered='true'] {
  background-color: var(--hlx-color-background-secondary);
  cursor: pointer;
}

.hlx-table tr[data-pressed='true'] {
  background-color: var(--hlx-color-background-tertiary);
  cursor: pointer;
}

.hlx-table td {
  padding: var(--hlx-spacing-x4);
  border-top: 1px solid var(--hlx-color-border-primary);
  border-bottom: 1px solid var(--hlx-color-border-primary);
  outline: none;
}

.hlx-table td[data-focused='true'] {
  outline: 2px solid var(--hlx-color-border-focus);
}

.hlx-modal.hlx-modal-root {
  background: var(--hlx-color-background-primary);
  transform: scale(var(--scale, 1));
  contain: content;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  transition: transform 0.12s ease-in-out;
  display: grid;
}

.hlx-modal .hlx-modal-header {
  background-color: var(--hlx-color-background-primary);
  padding: var(--hlx-spacing-x5);
  align-items: center;
  gap: var(--hlx-spacing-x2);
  display: flex;
  position: sticky;
  top: 0;
}

@media screen and (min-width: 768px) {
  .hlx-modal .hlx-modal-header {
    border-bottom: 1px solid var(--hlx-color-border-primary);
    grid-template-columns: 1fr 1fr auto;
  }
}

.hlx-modal .hlx-modal-header > .hlx-icon-button:first-child {
  grid-area: 1 / 1;
}

@media screen and (min-width: 768px) {
  .hlx-modal .hlx-modal-header > .hlx-icon-button:first-child {
    display: none;
  }
}

.hlx-modal .hlx-modal-header > .hlx-modal-heading + .hlx-icon-button {
  grid-area: 1 / 1;
}

.hlx-modal .hlx-modal-header > .hlx-icon-button svg {
  width: 28px;
  height: 28px;
}

@media screen and (min-width: 768px) {
  .hlx-modal .hlx-modal-header > .hlx-icon-button svg {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 768px) {
  .hlx-modal[data-hlx-variant='content'] {
    border-radius: 6px;
    width: min(100vw - 40px, 600px);
    height: auto;
    max-height: calc(100vh - 40px);
  }
}

.hlx-modal[data-hlx-variant='content'] .hlx-modal-content-inner {
  padding: var(--hlx-spacing-x5);
}

.hlx-modal[data-hlx-variant='sidesheet'] {
  box-shadow: var(--hlx-elevation-medium);
}

@media screen and (min-width: 768px) {
  .hlx-modal[data-hlx-variant='sidesheet'][data-hlx-sidesheet-width='narrow'] {
    width: 440px;
    height: 100vh;
  }
}

@media screen and (min-width: 768px) {
  .hlx-modal[data-hlx-variant='sidesheet'][data-hlx-sidesheet-width='wide'] {
    width: 720px;
    height: 100vh;
  }
}

.hlx-modal .hlx-modal-footer {
  grid-row: 2;
  position: sticky;
  bottom: 0;
}

.hlx-modal .hlx-modal-content {
  grid-row: 1 / 2;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.hlx-modal[data-hlx-variant='sidesheet'] .hlx-modal-content-inner {
  padding: var(--hlx-spacing-x5);
  background: var(--hlx-color-background-primary);
  height: 100%;
  margin: 0 auto;
}

.hlx-modal[data-hlx-variant='fullscreen'][data-hlx-layout='contained']
  .hlx-modal-content-inner {
  padding: var(--hlx-spacing-x5);
  background: var(--hlx-color-background-primary);
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
}

.hlx-modal[data-hlx-variant='fullscreen'][data-hlx-layout='full-bleed']
  .hlx-modal-content-inner {
  height: 100%;
}

.hlx-modal .hlx-modal-content-outer {
  grid-template-rows: subgrid;
  grid-row: 2 / 4;
  display: grid;
  position: relative;
}

.hlx-modal .hlx-modal-content-outer > .hlx-progress-bar {
  position: absolute;
  top: 0;
}

.hlx-modal .hlx-modal-footer {
  border-top: 1px solid var(--hlx-color-border-primary);
  background-color: var(--hlx-color-background-primary);
}

.hlx-modal .hlx-modal-footer .hlx-modal-footer-inner {
  padding: var(--hlx-spacing-x5);
  align-items: center;
  gap: var(--hlx-spacing-x2);
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
}

@media screen and (min-width: 768px) {
  .hlx-modal .hlx-modal-footer .hlx-modal-footer-inner {
    justify-content: flex-end;
    display: flex;
  }
}

.hlx-modal
  .hlx-modal-footer
  .hlx-modal-footer-inner
  > .hlx-button[type='submit'] {
  order: 999;
}

.hlx-modal
  .hlx-modal-footer
  .hlx-modal-footer-inner
  > .hlx-modal-footer-tertiary-slot {
  margin-right: auto;
  display: none;
}

@media screen and (min-width: 768px) {
  .hlx-modal
    .hlx-modal-footer
    .hlx-modal-footer-inner
    > .hlx-modal-footer-tertiary-slot {
    display: block;
  }
}

.hlx-modal .hlx-modal-heading {
  color: var(--hlx-color-foreground-primary);
  text-align: center;
  flex: 1;
  justify-self: center;
}

.hlx-modal .hlx-modal-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-11);
  line-height: var(--hlx-typography-line-height-base-8);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.01em;
}

@media screen and (min-width: 768px) {
  .hlx-modal .hlx-modal-heading {
    text-align: left;
    justify-self: auto;
  }
}

.hlx-modal .hlx-modal-heading .hlx-modal-heading-progress {
  color: var(--hlx-color-foreground-primary);
  margin-left: var(--hlx-spacing-x2);
}

.hlx-modal .hlx-modal-heading .hlx-modal-heading-progress {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-modal .hlx-modal-heading .hlx-modal-heading-progress {
    font-size: 17px;
    display: none;
  }
}

.hlx-modal .hlx-progress-bar-value-label {
  display: none;
}

.hlx-promotional-modal {
  border-radius: 6px;
  width: min(100vw - 40px, 600px);
  height: auto;
  max-height: calc(100vh - 40px);
  font-size: 0;
  position: relative;
  overflow: hidden;
}

.hlx-promotional-modal .hlx-promotional-modal-dialog {
  outline: none;
}

.hlx-promotional-modal .hlx-promotional-modal-dismiss {
  top: var(--hlx-spacing-x3);
  right: var(--hlx-spacing-x4);
  padding: var(--hlx-spacing-x2);
  aspect-ratio: 1;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
}

.hlx-promotional-modal .hlx-modal-content-inner {
  background-color: var(--hlx-color-background-primary);
  padding: var(--hlx-spacing-x5);
}

.hlx-promotional-modal .hlx-modal-footer-inner {
  background-color: var(--hlx-color-background-primary);
  padding: var(--hlx-spacing-x5);
  border-top: 1px solid var(--hlx-color-border-primary);
  gap: var(--hlx-spacing-x2);
  display: grid;
}

@media screen and (min-width: 768px) {
  .hlx-promotional-modal .hlx-modal-footer-inner {
    justify-content: flex-end;
    display: flex;
  }
}

.hlx-promotional-modal
  .hlx-modal-footer-inner
  .hlx-button[data-hlx-variant='primary'] {
  order: 999;
}

.hlx-modal-underlay {
  z-index: var(--hlx-layer-modal);
  background-color: #3535354d;
  justify-content: center;
  align-items: center;
  width: 100vw;
  display: flex;
  position: fixed;
  inset: 0;
}

.hlx-modal-underlay[data-hlx-variant='sidesheet'] {
  justify-content: flex-end;
}

.hlx-toast-region {
  bottom: var(--hlx-spacing-x5);
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.hlx-toast-region .hlx-toast {
  background-color: var(--hlx-color-background-inverted-primary);
  box-shadow: var(--hlx-elevation-medium);
  padding: var(--hlx-spacing-x2) var(--hlx-spacing-x4);
  align-items: flex-start;
  gap: var(--hlx-spacing-x3);
  --slide-from: translateY(100%);
  --slide-to: translateY(0);
  border-radius: 4px;
  width: fit-content;
  display: flex;
}

@media screen and (max-width: 767.999px) {
  .hlx-toast-region .hlx-toast {
    max-width: max(80%, 400px);
  }
}

.hlx-toast-region .hlx-toast[data-animation='entering'] {
  animation: 0.3s slide-in;
}

.hlx-toast-region .hlx-toast[data-animation='exiting'] {
  animation: 0.3s forwards fade-out;
}

.hlx-toast-region .hlx-toast .hlx-toast-type-icon {
  flex-shrink: 0;
}

.hlx-toast-region .hlx-toast .hlx-toast-body {
  align-items: flex-start;
  gap: var(--hlx-spacing-x6);
  display: flex;
}

@media screen and (max-width: 767.999px) {
  .hlx-toast-region .hlx-toast .hlx-toast-body {
    gap: var(--hlx-spacing-x3);
    flex-direction: column;
  }
}

.hlx-toast-region .hlx-toast .hlx-toast-body .hlx-button {
  color: var(--hlx-color-foreground-inverted-primary);
  --hover-text-color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-toast-region .hlx-toast .hlx-toast-content {
  color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-toast-region .hlx-toast .hlx-toast-content {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-toast-region .hlx-toast .hlx-toast-content {
    font-size: 17px;
  }
}

.hlx-progress-bar {
  gap: var(--hlx-spacing-x2);
  isolation: isolate;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.hlx-progress-bar .hlx-progress-bar-track {
  background: var(--hlx-color-border-primary);
  z-index: 1;
  width: 100%;
  height: 4px;
  overflow: hidden;
}

.hlx-progress-bar .hlx-progress-bar-fill {
  background: var(--hlx-color-foreground-compliance);
  border: none;
  height: 4px;
  transition: width 1s;
}

.hlx-progress-bar .hlx-progress-bar-value-label {
  color: var(--hlx-color-foreground-secondary);
  white-space: nowrap;
  align-self: flex-end;
}

.hlx-progress-bar .hlx-progress-bar-value-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-description-list {
  gap: var(--hlx-spacing-x3);
  grid-template-columns: minmax(min-content, 33%) 1fr;
  margin: 0;
  display: grid;
}

.hlx-description-list-term {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-description-list-term {
    font-size: 17px;
  }
}

.hlx-description-list-description {
  margin: 0;
}

.hlx-description-list-description {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-description-list-description {
    font-size: 17px;
  }
}

.hlx-date-segment {
  text-align: flex-end;
}

.hlx-date-segment.hlx-date-segment-editable {
  font-variant-numeric: tabular-nums;
  text-align: end;
  box-sizing: content-box;
  white-space: nowrap;
  color: var(--color);
  min-width: var(--chars);
  background: none;
  border: none;
}

.hlx-date-segment.hlx-date-segment-editable::selection {
  background: none;
}

.hlx-date-segment.hlx-date-segment-editable:focus {
  background-color: Highlight;
  caret-color: #0000;
  outline: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .hlx-date-segment.hlx-date-segment-editable:focus {
    background-color: SelectedItem;
  }
}

.hlx-date-segment.hlx-date-segment-editable:focus
  .hlx-date-segment-placeholder {
  color: gray;
}

.hlx-date-segment.hlx-date-segment-editable[data-placeholder] {
  width: auto;
}

.hlx-date-segment.hlx-date-segment-editable .hlx-date-segment-placeholder {
  text-align: center;
  visibility: hidden;
  pointer-events: none;
  color: var(--hlx-color-foreground-disabled);
  width: 100%;
  height: 0;
  font-size: 0;
  display: block;
}

:is([data-hlx-focused], [data-empty='false'])
  .hlx-date-segment.hlx-date-segment-editable[data-placeholder]
  .hlx-date-segment-placeholder {
  visibility: visible;
  font-size: inherit;
  text-transform: uppercase;
  font-variant-numeric: tabular-nums;
  height: auto;
}

.hlx-date-segment.hlx-date-segment-literal {
  visibility: hidden;
  padding: 0 2px;
}

:is([data-hlx-focused], [data-empty='false'])
  .hlx-date-segment.hlx-date-segment-literal {
  visibility: visible;
}

.hlx-date-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-date-field-root .hlx-date-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-date-field-root .hlx-date-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-field-root .hlx-date-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-date-field-root .hlx-date-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-field-root .hlx-date-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-date-field-root .hlx-date-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-field-root .hlx-date-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-date-field-root .hlx-date-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-field-root .hlx-date-field-control {
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  width: 100%;
  padding: 6px 44px 6px 12px;
  display: flex;
}

.hlx-date-field-root .hlx-date-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-date-field-root .hlx-date-field-control {
    font-size: 17px;
  }
}

.hlx-date-field-root .hlx-date-field-adornment {
  pointer-events: none;
  color: var(--color);
  grid-area: 2 / 1 / 3 / 3;
  place-self: center flex-end;
  width: fit-content;
  margin-right: 12px;
  display: flex;
}

.hlx-date-field-root[data-hlx-validation='invalid'] .hlx-date-field-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-date-field-root[data-hlx-validation='invalid'] .hlx-date-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-date-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-date-field-root[data-hlx-disabled='true'] .hlx-date-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-date-field-root[data-hlx-disabled='true'] .hlx-date-field-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-date-field-root[data-hlx-disabled='false']
  .hlx-date-field-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-date-field-root[data-hlx-disabled='false']
  .hlx-date-field-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-date-field-root .hlx-date-field-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-time-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-time-field-root .hlx-time-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-time-field-root .hlx-time-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-time-field-root .hlx-time-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-time-field-root .hlx-time-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-time-field-root .hlx-time-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-time-field-root .hlx-time-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-time-field-root .hlx-time-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-time-field-root .hlx-time-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-time-field-root .hlx-time-field-control {
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  width: 100%;
  padding: 6px 44px 6px 12px;
  display: flex;
}

.hlx-time-field-root .hlx-time-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-time-field-root .hlx-time-field-control {
    font-size: 17px;
  }
}

.hlx-time-field-root[data-hlx-validation='invalid'] .hlx-time-field-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-time-field-root[data-hlx-validation='invalid'] .hlx-time-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-time-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-time-field-root[data-hlx-disabled='true'] .hlx-time-field-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-time-field-root[data-hlx-disabled='true'] .hlx-time-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-time-field-root[data-hlx-disabled='false']
  .hlx-time-field-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-time-field-root[data-hlx-disabled='false']
  .hlx-time-field-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-time-field-root .hlx-time-field-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-date-picker-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-date-picker-field-root .hlx-date-picker-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-date-picker-field-root .hlx-date-picker-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-picker-field-root .hlx-date-picker-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-date-picker-field-root .hlx-date-picker-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-picker-field-root .hlx-date-picker-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-date-picker-field-root .hlx-date-picker-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-picker-field-root .hlx-date-picker-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-date-picker-field-root .hlx-date-picker-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-date-picker-field-root .hlx-date-picker-field-control-group {
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hlx-date-picker-field-root .hlx-date-picker-field-control {
  appearance: none;
  outline-offset: 2px;
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  outline: none;
  width: 100%;
  padding: 6px 12px;
  display: flex;
}

.hlx-date-picker-field-root .hlx-date-picker-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-date-picker-field-root .hlx-date-picker-field-control {
    font-size: 17px;
  }
}

.hlx-date-picker-field-root .hlx-date-picker-field-trigger {
  color: var(--color);
  appearance: none;
  outline-offset: -1px;
  background-color: #0000;
  border: none;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  place-self: stretch flex-end;
  align-items: center;
  width: fit-content;
  padding: 0 12px;
  display: flex;
}

.hlx-date-picker-field-root[data-hlx-validation='invalid']
  .hlx-date-picker-field-control-group {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-date-picker-field-root[data-hlx-validation='invalid']
  .hlx-date-picker-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-date-picker-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-date-picker-field-root[data-hlx-disabled='true']
  .hlx-date-picker-field-control,
.hlx-date-picker-field-root[data-hlx-disabled='true']
  .hlx-date-picker-field-trigger {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-date-picker-field-root[data-hlx-disabled='true']
  .hlx-date-picker-field-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-date-picker-field-root[data-hlx-disabled='false']
  .hlx-date-picker-field-control-group[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-date-picker-field-root[data-hlx-disabled='false']
  .hlx-date-picker-field-control-group.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-date-picker-field-root .hlx-date-picker-field-control-group.focus-ring,
.hlx-date-picker-field-root .hlx-date-picker-field-trigger.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-date-picker-field-dialog {
  background: var(--hlx-color-background-primary);
  box-shadow: var(--hlx-elevation-light);
  border: 1px solid var(--hlx-color-border-primary);
  border-radius: 4px;
}

.hlx-date-picker-field-calendar-header {
  padding: var(--hlx-spacing-x3) var(--hlx-spacing-x3) 0;
  align-items: center;
  gap: var(--hlx-spacing-x2);
  display: flex;
}

.hlx-date-picker-field-preset {
  padding: var(--hlx-spacing-x3);
  border-bottom: 1px solid var(--hlx-color-border-primary);
  display: flex;
}

.hlx-date-picker-field-preset > * {
  flex-grow: 1;
  width: 0;
}

.hlx-date-picker-field-calendar-nav {
  align-items: center;
  margin-left: auto;
  display: flex;
}

.hlx-date-picker-field-calendar-grid {
  padding: 0 var(--hlx-spacing-x2) var(--hlx-spacing-x3);
}

.hlx-date-picker-field-calendar-grid table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.hlx-date-picker-field-calendar-grid td,
.hlx-date-picker-field-calendar-grid th {
  width: 48px;
  height: 48px;
  padding: 0;
  display: table-cell;
  position: relative;
}

:is(
    .hlx-date-picker-field-calendar-grid td,
    .hlx-date-picker-field-calendar-grid th
  ) {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  :is(
      .hlx-date-picker-field-calendar-grid td,
      .hlx-date-picker-field-calendar-grid th
    ) {
    font-size: 15px;
  }
}

.hlx-date-picker-field-calendar-grid td {
  padding: 2px 0;
}

.hlx-date-picker-field-calendar-grid th {
  color: var(--hlx-color-foreground-secondary);
}

.hlx-date-picker-field-calendar-date {
  --color: var(--hlx-color-foreground-primary);
  outline: none;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 44px;
  display: flex;
}

.hlx-date-picker-field-calendar-date[hidden] {
  display: none;
}

.hlx-date-picker-field-calendar-date .hlx-calendar-cell-date-highlight {
  --background-color: transparent;
  --outline-color: var(--hlx-color-border-focus);
  background-color: var(--background-color);
  color: var(--color);
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  display: flex;
}

.hlx-date-picker-field-calendar-date[data-hovered='true']
  .hlx-calendar-cell-date-highlight {
  border: 1px solid var(--hlx-color-border-tertiary);
}

.hlx-date-picker-field-calendar-date[data-is-range-selection='true'] {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-date-picker-field-calendar-date[data-selected='true']
  .hlx-calendar-cell-date-highlight {
  --background-color: var(--hlx-color-background-inverted-primary);
  --color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-date-picker-field-calendar-date[data-selected='true'][data-selection-start='true'] {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  width: 46px;
  position: absolute;
  top: 2px;
  right: 0;
}

.hlx-date-picker-field-calendar-date[data-selected='true'][data-selection-start='true']
  .hlx-calendar-cell-date-highlight {
  --background-color: var(--hlx-color-background-inverted-primary);
  --color: var(--hlx-color-foreground-inverted-primary);
  width: 44px;
  margin-left: -2px;
}

.hlx-date-picker-field-calendar-date[data-selected='true'][data-selection-end='true'] {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 46px;
  position: absolute;
  top: 2px;
  left: 0;
}

.hlx-date-picker-field-calendar-date[data-selected='true'][data-selection-end='true']
  .hlx-calendar-cell-date-highlight {
  --background-color: var(--hlx-color-background-inverted-primary);
  --color: var(--hlx-color-foreground-inverted-primary);
  width: 44px;
  margin-right: -2px;
}

.hlx-date-picker-field-calendar-date[data-selected='true'][data-is-range-selection='true'][data-selection-start='false'][data-selection-end='false']
  .hlx-calendar-cell-date-highlight {
  --background-color: var(--hlx-color-background-tertiary);
  --color: var(--hlx-color-foreground-inverted-primary);
}

.hlx-date-picker-field-calendar-date[data-disabled='true'],
.hlx-date-picker-field-calendar-date[data-available='false'] {
  --color: var(--hlx-color-foreground-disabled);
}

.hlx-date-picker-field-calendar-date[data-focused='true']
  .hlx-calendar-cell-date-highlight {
  border: 1px solid var(--hlx-color-border-tertiary);
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
}

.hlx-date-picker-field-calendar-date[data-significant='true']
  .hlx-calendar-cell-date-significance-indicator {
  background-color: var(--color);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  top: 35px;
  left: 22px;
}

.hlx-datepicker-select .hlx-datepicker-select-control {
  --background-color: var(--hlx-color-background-primary);
  --color: var(--hlx-color-foreground-primary);
  --border-color: transparent;
  --outline-color: var(--hlx-color-border-focus);
  align-items: center;
  gap: var(--hlx-spacing-x2);
  background-color: var(--background-color);
  color: var(--color);
  border: 1px solid var(--border-color);
  outline-offset: 2px;
  padding: var(--hlx-spacing-x1) var(--hlx-spacing-x3);
  border-radius: 4px;
  outline: none;
  display: flex;
}

.hlx-datepicker-select .hlx-datepicker-select-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-datepicker-select .hlx-datepicker-select-control[data-hovered='true'] {
  --background-color: var(--hlx-color-background-tertiary);
}

.hlx-datepicker-select .hlx-datepicker-select-control[data-active='true'] {
  --background-color: var(--hlx-color-background-quartenary);
}

.hlx-datepicker-select .hlx-datepicker-select-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-form {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.hlx-form-sticky-footer {
  border-top: 1px solid var(--hlx-color-border-primary);
  background-color: var(--hlx-color-background-primary);
  margin: 0 -16px;
  padding: 20px;
  position: sticky;
  bottom: 0;
  left: 0;
}

.hlx-form-sticky-footer .hlx-form-sticky-footer-content {
  justify-content: flex-end;
  align-items: center;
  gap: var(--hlx-spacing-x2);
  flex-direction: row;
  max-width: 895px;
  margin: 0 auto;
  display: flex;
}

.hlx-form-sticky-footer .hlx-form-sticky-footer-error {
  color: var(--hlx-color-foreground-danger-secondary);
  margin-right: auto;
}

.hlx-form-sticky-footer .hlx-form-sticky-footer-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-form-sticky-footer .hlx-form-sticky-footer-error {
    font-size: 17px;
  }
}

.hlx-checkbox-root .hlx-checkbox-label {
  --control-size: 20px;
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  color: var(--hlx-color-foreground-primary);
  grid-template-columns: var(--control-size) auto;
  gap: var(--hlx-spacing-x2);
  align-items: flex-start;
  display: grid;
  position: relative;
}

.hlx-checkbox-root .hlx-checkbox-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-root .hlx-checkbox-label {
    --control-size: 24px;
    padding: 10px 0;
    font-size: 17px;
  }
}

.hlx-checkbox-root .hlx-checkbox-control {
  width: var(--control-size);
  height: var(--control-size);
  color: var(--hlx-color-background-disabled);
}

.hlx-checkbox-root[data-hlx-hovered='true'] .hlx-checkbox-label {
  cursor: pointer;
  color: var(--hlx-color-foreground-primary);
}

.hlx-checkbox-root[data-hlx-hovered='true'] .hlx-checkbox-control {
  color: var(--hlx-color-foreground-primary);
}

.hlx-checkbox-root[data-hlx-disabled='true'] .hlx-checkbox-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-checkbox-root[data-hlx-disabled='true'] .hlx-checkbox-control {
  fill: var(--hlx-color-background-disabled);
  color: var(--hlx-color-border-disabled);
}

.hlx-checkbox-root .hlx-checkbox-control.focus-ring {
  outline: 2px solid var(--hlx-color-border-focus);
  border-radius: 2px;
}

:is(
    .hlx-checkbox-root[data-selected='true'],
    .hlx-checkbox-root:where(:has(input:checked)),
    .hlx-checkbox-root:where(:has(input:indeterminate))
  )
  .hlx-checkbox-control {
  color: var(--hlx-color-foreground-primary);
}

:is(
    .hlx-checkbox-root[data-selected='true'],
    .hlx-checkbox-root:where(:has(input:checked)),
    .hlx-checkbox-root:where(:has(input:indeterminate))
  )[data-hlx-disabled='true']
  .hlx-checkbox-control {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-checkbox-root[data-hlx-validation='invalid'] .hlx-checkbox-control {
  color: var(--hlx-color-foreground-danger);
}

.hlx-checkbox-root[data-hlx-validation='invalid'] .hlx-checkbox-error {
  color: var(--hlx-color-foreground-danger-secondary);
  margin-top: var(--hlx-spacing-x1);
}

.hlx-checkbox-root[data-hlx-validation='invalid'] .hlx-checkbox-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-root[data-hlx-validation='invalid'] .hlx-checkbox-error {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-checkbox-root[data-hlx-validation='invalid'] .hlx-checkbox-error {
      font-size: 15px;
    }
  }
}

.hlx-checkbox-group-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
}

.hlx-checkbox-group-root .hlx-checkbox-group-descriptors {
  align-items: flex-start;
  gap: var(--hlx-spacing-x2);
  margin-bottom: var(--hlx-spacing-x2);
  flex-direction: row;
  display: flex;
}

.hlx-checkbox-group-root .hlx-checkbox-group-help-text {
  color: var(--hlx-color-foreground-secondary);
  margin-top: var(--hlx-spacing-x1);
}

.hlx-checkbox-group-root .hlx-checkbox-group-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root .hlx-checkbox-group-help-text {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-checkbox-group-root .hlx-checkbox-group-help-text {
      font-size: 15px;
    }
  }
}

.hlx-checkbox-group-root .hlx-checkbox-group-error {
  color: var(--hlx-color-foreground-danger-secondary);
  margin-top: var(--hlx-spacing-x1);
}

.hlx-checkbox-group-root .hlx-checkbox-group-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root .hlx-checkbox-group-error {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-checkbox-group-root .hlx-checkbox-group-error {
      font-size: 15px;
    }
  }
}

.hlx-checkbox-group-root .hlx-checkbox-group-optionality-text {
  color: var(--hlx-color-foreground-secondary);
}

.hlx-checkbox-group-root .hlx-checkbox-group-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root .hlx-checkbox-group-optionality-text {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-checkbox-group-root .hlx-checkbox-group-optionality-text {
      font-size: 15px;
    }
  }
}

.hlx-checkbox-group-root .hlx-checkbox-group-controls {
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root .hlx-checkbox-group-controls {
    row-gap: 0;
  }
}

.hlx-checkbox-group-root[data-hlx-size='small'] .hlx-checkbox-group-label {
  color: var(--hlx-color-foreground-primary);
  display: block;
}

.hlx-checkbox-group-root[data-hlx-size='small'] .hlx-checkbox-group-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root[data-hlx-size='small'] .hlx-checkbox-group-label {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-checkbox-group-root[data-hlx-size='small'] .hlx-checkbox-group-label {
      font-size: 15px;
    }
  }
}

.hlx-checkbox-group-root[data-hlx-size='large'] .hlx-checkbox-group-label {
  color: var(--hlx-color-foreground-primary);
  display: block;
}

.hlx-checkbox-group-root[data-hlx-size='large'] .hlx-checkbox-group-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-checkbox-group-root[data-hlx-size='large'] .hlx-checkbox-group-label {
    font-size: 17px;
  }
}

.hlx-checkbox-group-root[data-hlx-disabled='true'] .hlx-checkbox-group-label,
.hlx-checkbox-group-root[data-hlx-disabled='true']
  .hlx-checkbox-group-help-text,
.hlx-checkbox-group-root[data-hlx-disabled='true']
  .hlx-checkbox-group-optionality-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-radio-root {
  --control-size: 20px;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  gap: 2px 8px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .hlx-radio-root {
    --control-size: 24px;
    padding: 10px 0;
  }
}

.hlx-radio-root .hlx-radio-control {
  width: var(--control-size);
  height: var(--control-size);
  color: var(--hlx-color-background-disabled);
  grid-column: 1 / 2;
}

.hlx-radio-root:has(input:checked) .hlx-radio-control {
  color: var(--hlx-color-foreground-primary);
}

.hlx-radio-root[data-hlx-validation='invalid'] .hlx-radio-control,
.hlx-radio-root[data-hlx-validation='invalid']:has(input:checked)
  .hlx-radio-control {
  color: var(--hlx-color-foreground-danger);
}

.hlx-radio-root[data-hlx-hovered='true'] .hlx-radio-control {
  color: var(--hlx-color-foreground-primary);
}

.hlx-radio-root .hlx-radio-control.focus-ring {
  outline: 2px solid var(--hlx-color-border-focus);
  border-radius: 50%;
}

.hlx-radio-root .hlx-radio-description {
  margin-left: calc(var(--control-size) + var(--hlx-spacing-x2));
  color: var(--hlx-color-foreground-primary);
  grid-area: 2 / 1 / auto / -1;
}

.hlx-radio-root .hlx-radio-description {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-root .hlx-radio-description {
    font-size: 17px;
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-regular);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-radio-root .hlx-radio-description {
      font-size: 15px;
    }
  }
}

.hlx-radio-root .hlx-radio-badge {
  grid-area: 1 / 2 / auto / 3;
}

.hlx-radio-root .hlx-radio-label {
  color: var(--hlx-color-foreground-primary);
  position: relative;
}

.hlx-radio-root .hlx-radio-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-root .hlx-radio-label {
    font-size: 17px;
  }
}

.hlx-radio-root .hlx-radio-label:has(~ .hlx-radio-description) {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-root .hlx-radio-label:has(~ .hlx-radio-description) {
    font-size: 17px;
  }
}

.hlx-radio-root .hlx-radio-label > span {
  gap: var(--hlx-spacing-x2);
  grid-template-columns: min-content auto;
  display: grid;
}

.hlx-radio-root[data-hlx-disabled='true'] .hlx-radio-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-radio-root[data-hlx-disabled='true'] .hlx-radio-control {
  fill: var(--hlx-color-background-disabled);
  color: var(--hlx-color-border-disabled);
}

.hlx-radio-root[data-hlx-disabled='true'] .hlx-radio-description {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-radio-group-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  flex-direction: column;
  display: flex;
}

.hlx-radio-group-root[data-hlx-size='small'] {
  gap: 4px;
}

.hlx-radio-group-root[data-hlx-size='large'] {
  gap: 8px;
}

.hlx-radio-group-root .hlx-radio-group-help-text {
  color: var(--hlx-color-foreground-secondary);
  margin-top: var(--hlx-spacing-x1);
}

.hlx-radio-group-root .hlx-radio-group-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root .hlx-radio-group-help-text {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-radio-group-root .hlx-radio-group-help-text {
      font-size: 15px;
    }
  }
}

.hlx-radio-group-root .hlx-radio-group-error {
  color: var(--hlx-color-foreground-danger-secondary);
  margin-top: var(--hlx-spacing-x1);
}

.hlx-radio-group-root .hlx-radio-group-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root .hlx-radio-group-error {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-radio-group-root .hlx-radio-group-error {
      font-size: 15px;
    }
  }
}

.hlx-radio-group-root .hlx-radio-group-optionality-text {
  color: var(--hlx-color-foreground-secondary);
}

.hlx-radio-group-root .hlx-radio-group-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root .hlx-radio-group-optionality-text {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-radio-group-root .hlx-radio-group-optionality-text {
      font-size: 15px;
    }
  }
}

.hlx-radio-group-root .hlx-radio-group-controls {
  gap: var(--hlx-spacing-x2);
  padding-left: var(--hlx-spacing-x1);
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root:not(.hlx-radio-card-group) .hlx-radio-group-controls {
    row-gap: 0;
  }
}

.hlx-radio-group-root .hlx-radio-group-label {
  color: var(--hlx-color-foreground-primary);
  display: inline;
}

.hlx-radio-group-root[data-hlx-size='small'] .hlx-radio-group-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root[data-hlx-size='small'] .hlx-radio-group-label {
    font-family: var(--hlx-typography-font-family-content);
    font-size: var(--hlx-typography-font-size-base-5);
    line-height: var(--hlx-typography-line-height-base-4);
    font-weight: var(--hlx-typography-font-weight-content-medium);
    letter-spacing: normal;
  }

  @media screen and (max-width: 768px) {
    .hlx-radio-group-root[data-hlx-size='small'] .hlx-radio-group-label {
      font-size: 15px;
    }
  }
}

.hlx-radio-group-root[data-hlx-size='large'] .hlx-radio-group-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-radio-group-root[data-hlx-size='large'] .hlx-radio-group-label {
    font-size: 17px;
  }
}

.hlx-radio-group-root[data-hlx-disabled='true'] .hlx-radio-group-label,
.hlx-radio-group-root[data-hlx-disabled='true']
  .hlx-radio-group-optionality-text,
.hlx-radio-group-root[data-hlx-disabled='true'] .hlx-radio-group-help-text,
.hlx-radio-group-root[data-hlx-disabled='true'] .hlx-badge {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-radio-group-root .hlx-radio-group-descriptors:not(:empty) {
  margin-bottom: var(--hlx-spacing-x2);
}

.hlx-radio-group-root[data-hlx-validation='invalid'] .hlx-radio-control {
  color: var(--hlx-color-foreground-danger);
}

.hlx-radio-card-group .hlx-radio-group-controls {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-left: 0;
  display: grid;
}

.hlx-radio-card-group .hlx-radio-root {
  --border-color: var(--hlx-color-border-primary);
  --bg-color: transparent;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  padding: var(--hlx-spacing-x3);
  border-radius: 4px;
  position: relative;
}

.hlx-radio-card-group .hlx-radio-root .hlx-radio-label {
  display: contents;
}

.hlx-radio-card-group .hlx-radio-root .hlx-radio-label:after {
  content: '';
  top: calc(-1 * var(--hlx-spacing-x3));
  right: calc(-1 * var(--hlx-spacing-x3));
  bottom: calc(-1 * var(--hlx-spacing-x3));
  left: calc(-1 * var(--hlx-spacing-x3));
  z-index: 0;
  grid-area: 1 / 1 / 2 / 3;
  position: absolute;
}

.hlx-radio-card-group
  .hlx-radio-root:has(.hlx-radio-description)
  .hlx-radio-label:after {
  grid-row: 1 / 3;
}

.hlx-radio-card-group .hlx-radio-root[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-foreground-primary);
  --bg-color: var(--hlx-color-background-primary);
}

.hlx-radio-card-group
  .hlx-radio-root[data-hlx-hovered='true']
  .hlx-badge[data-hlx-variant='neutral'] {
  background-color: var(--hlx-color-background-quartenary);
}

.hlx-radio-card-group .hlx-radio-root[data-hlx-selected='true'] {
  --bg-color: var(--hlx-color-background-tertiary);
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-radio-card-group
  .hlx-radio-root[data-hlx-disabled='true']
  .hlx-radio-control {
  color: var(--hlx-color-background-disabled);
}

.hlx-radio-card-group
  .hlx-radio-root[data-hlx-disabled='true'][data-hlx-selected='true'] {
  --bg-color: var(--hlx-color-background-secondary);
  --border-color: var(--hlx-color-background-disabled);
}

.hlx-radio-card-group .hlx-radio-root:has(.hlx-radio-control.focus-ring) {
  --border-color: var(--hlx-color-foreground-primary);
  --bg-color: var(--hlx-color-background-tertiary);
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
}

.hlx-radio-card-group .hlx-radio-root[data-hlx-validation='invalid'] {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-switch-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  justify-content: flex-start;
  gap: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-primary);
  display: flex;
}

.hlx-switch-root {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-switch-root {
    font-size: 17px;
  }
}

.hlx-switch-root .hlx-switch-track {
  fill: var(--track-color);
  stroke: var(--track-color);
}

.hlx-switch-root .hlx-switch-handle {
  fill: var(--handle-color);
}

.hlx-switch-root[data-hlx-selected='true'] {
  --track-color: var(--hlx-color-foreground-primary);
  --handle-color: var(--hlx-color-background-primary);
}

.hlx-switch-root[data-hlx-selected='true'][data-hlx-hovered='true']
  .hlx-switch-icon {
  cursor: pointer;
}

.hlx-switch-root[data-hlx-selected='false'] {
  --track-color: var(--hlx-color-background-tertiary);
  --handle-color: var(--hlx-color-foreground-secondary);
}

.hlx-switch-root[data-hlx-selected='false'][data-hlx-hovered='true']
  .hlx-switch-icon {
  --track-color: var(--hlx-color-background-quartenary);
  cursor: pointer;
}

.hlx-switch-root[data-hlx-hovered='true'] {
  cursor: pointer;
  color: var(--hlx-color-foreground-primary);
}

.hlx-switch-root .focus-ring {
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  border-radius: 24px;
}

.hlx-switch-root[data-hlx-disabled='true'][data-hlx-selected='true'] {
  color: var(--hlx-color-foreground-disabled);
  --track-color: var(--hlx-color-background-disabled);
  --handle-color: var(--hlx-color-background-primary);
}

.hlx-switch-root[data-hlx-disabled='true'][data-hlx-selected='false'] {
  --track-color: transparent;
  --handle-color: var(--hlx-color-background-disabled);
}

.hlx-switch-root[data-hlx-disabled='true'][data-hlx-selected='false']
  .hlx-switch-track {
  stroke: var(--hlx-color-background-disabled);
}

.hlx-switch-root[data-hlx-validation='invalid'] {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-switch-root[data-hlx-validation='invalid'][data-hlx-selected='true'] {
  --track-color: var(--hlx-color-foreground-danger);
  --handle-color: var(--hlx-color-background-primary);
}

.hlx-switch-root[data-hlx-validation='invalid'][data-hlx-selected='false'] {
  --track-color: transparent;
  --handle-color: var(--hlx-color-foreground-danger);
}

.hlx-switch-root[data-hlx-validation='invalid'][data-hlx-selected='false']
  .hlx-switch-track {
  stroke: var(--hlx-color-border-danger);
}

.hlx-text-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-primary);
  --secondary-descriptor-color: var(--hlx-color-foreground-secondary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-text-field-root .hlx-text-field-descriptors {
  gap: var(--hlx-spacing-x1) 0;
  grid-area: 1 / 1 / 2 / 2;
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-text-field-root .hlx-text-field-descriptors .hlx-text-field-label {
  color: var(--primary-descriptor-color);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-text-field-root .hlx-text-field-descriptors .hlx-text-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-field-root
  .hlx-text-field-descriptors
  .hlx-text-field-instructional-text {
  color: var(--primary-descriptor-color);
  grid-area: 2 / 1 / 3 / 3;
}

.hlx-text-field-root
  .hlx-text-field-descriptors
  .hlx-text-field-instructional-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-text-field-root
  .hlx-text-field-descriptors
  .hlx-text-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--secondary-descriptor-color);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-text-field-root
  .hlx-text-field-descriptors
  .hlx-text-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-field-root .hlx-text-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-text-field-root .hlx-text-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-field-root .hlx-text-field-help-text {
  color: var(--secondary-descriptor-color);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-text-field-root .hlx-text-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-field-root .hlx-text-field-control {
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  width: 100%;
  padding: 6px 12px;
  display: block;
}

.hlx-text-field-root .hlx-text-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-text-field-root .hlx-text-field-control {
    font-size: 17px;
  }
}

.hlx-text-field-root .hlx-text-field-control::placeholder {
  color: var(--placeholder-color);
}

.hlx-text-field-root .hlx-text-field-adornment {
  grid-area: 2 / 1 / 3 / 3;
  align-self: center;
  width: fit-content;
  display: flex;
}

.hlx-text-field-root .hlx-text-field-control.hlx-text-field-adornment-end {
  padding-right: calc(24px + var(--adornment-width));
}

.hlx-text-field-root
  .hlx-text-field-control.hlx-text-field-adornment-end
  + .hlx-text-field-adornment {
  justify-self: flex-end;
  margin-right: 12px;
}

.hlx-text-field-root[data-hlx-validation='invalid'] .hlx-text-field-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-text-field-root[data-hlx-validation='invalid'] .hlx-text-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-text-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-disabled);
  --secondary-descriptor-color: var(--hlx-color-foreground-disabled);
}

.hlx-text-field-root[data-hlx-disabled='true'] .hlx-text-field-label,
.hlx-text-field-root[data-hlx-disabled='true'] .hlx-text-field-help-text,
.hlx-text-field-root[data-hlx-disabled='true'] .hlx-text-field-optionality-text,
.hlx-text-field-root[data-hlx-disabled='true']
  .hlx-text-field-instructional-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-text-field-root[data-hlx-disabled='true'] .hlx-text-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-text-field-root[data-hlx-disabled='false']
  .hlx-text-field-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-text-field-root .hlx-text-field-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-text-field-root .hlx-text-field-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-search-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  grid-template-columns: auto 1fr;
  display: grid;
  position: relative;
}

.hlx-search-field-root .hlx-search-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-column: 1 / 2;
  width: fit-content;
}

.hlx-search-field-root .hlx-search-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-search-field-root .hlx-search-field-control-group {
  grid-column: 1 / 3;
  display: flex;
  position: relative;
}

.hlx-search-field-root .hlx-icon {
  place-items: center;
  display: grid;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.hlx-search-field-root .hlx-clear-field-trigger {
  top: 50%;
  right: var(--hlx-spacing-x2);
  background-color: #0000;
  border: none;
  outline: none;
  place-items: center;
  display: grid;
  position: absolute;
  transform: translateY(-50%);
}

.hlx-search-field-root .hlx-search-field-control {
  --color: var(--hlx-color-foreground-primary);
  --outline-color: var(--hlx-color-border-focus);
  --border-color: var(--hlx-color-border-primary);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  appearance: none;
  outline-offset: 2px;
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  width: 100%;
  padding: 6px 32px 6px 34px;
  display: block;
}

.hlx-search-field-root .hlx-search-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-search-field-root .hlx-search-field-control {
    font-size: 17px;
  }
}

.hlx-search-field-root .hlx-search-field-control::placeholder {
  color: var(--placeholder-color);
}

.hlx-search-field-root .hlx-search-field-control::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.hlx-search-field-root .hlx-search-field-control::-webkit-search-decoration {
  -webkit-appearance: none;
}

.hlx-search-field-root[data-hlx-disabled='true'] .hlx-search-field-control {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  background-color: var(--hlx-color-background-secondary);
}

.hlx-search-field-root[data-hlx-disabled='true'] .hlx-icon,
.hlx-search-field-root[data-hlx-disabled='true'] .hlx-search-field-label {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-search-field-root[data-hlx-disabled='false']
  .hlx-search-field-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-search-field-root[data-hlx-disabled='false']
  .hlx-search-field-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-search-field-root[data-hlx-disabled='false'] .hlx-clear-field-trigger {
  cursor: pointer;
}

.hlx-search-field-root .hlx-search-field-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-masked-text-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-masked-text-field-root .hlx-masked-text-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-masked-text-field-root .hlx-masked-text-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-masked-text-field-root .hlx-masked-text-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-masked-text-field-root .hlx-masked-text-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-masked-text-field-root .hlx-masked-text-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-masked-text-field-root .hlx-masked-text-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-masked-text-field-root .hlx-masked-text-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-masked-text-field-root .hlx-masked-text-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-masked-text-field-root .hlx-masked-text-field-control-group {
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  color: var(--color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  align-items: center;
  width: 100%;
  min-height: 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hlx-masked-text-field-root .hlx-masked-text-field-control-group {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-masked-text-field-root .hlx-masked-text-field-control-group {
    font-size: 17px;
  }
}

.hlx-masked-text-field-root .hlx-masked-text-field-placeholder {
  pointer-events: none;
  position: absolute;
  left: 12px;
}

.hlx-masked-text-field-root .hlx-masked-text-field-placeholder {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-masked-text-field-root .hlx-masked-text-field-placeholder {
    font-size: 17px;
  }
}

.hlx-masked-text-field-root .hlx-masked-text-field-control {
  appearance: none;
  outline-offset: 2px;
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: none;
  outline: none;
  width: 100%;
  padding: 6px 12px;
  display: flex;
}

.hlx-masked-text-field-root .hlx-masked-text-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-masked-text-field-root .hlx-masked-text-field-control {
    font-size: 17px;
  }
}

.hlx-masked-text-field-root[data-hlx-validation='invalid']
  .hlx-masked-text-field-control-group {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-masked-text-field-root[data-hlx-validation='invalid']
  .hlx-masked-text-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-masked-text-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-masked-text-field-root[data-hlx-disabled='true']
  .hlx-masked-text-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-masked-text-field-root[data-hlx-disabled='true']
  .hlx-masked-text-field-label,
.hlx-masked-text-field-root[data-hlx-disabled='true']
  .hlx-masked-text-field-help-text,
.hlx-masked-text-field-root[data-hlx-disabled='true']
  .hlx-masked-text-field-optionality-text,
.hlx-masked-text-field-root[data-hlx-disabled='true']
  .hlx-masked-text-field-placeholder {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-masked-text-field-root[data-hlx-disabled='false']
  .hlx-masked-text-field-control-group.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-masked-text-field-root[data-hlx-disabled='false']
  .hlx-masked-text-field-control-group[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-masked-text-field-root .hlx-masked-text-field-control-group.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-credit-card-fieldset-root {
  gap: var(--hlx-spacing-x4) var(--hlx-spacing-x3);
  border: none;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  display: grid;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-validation='invalid']
  .hlx-credit-card-field-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='true']
  .hlx-credit-card-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='true']
  .hlx-credit-card-field-label,
.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='true']
  .hlx-credit-card-field-help-text,
.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='true']
  .hlx-credit-card-field-optionality-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='false']
  .hlx-credit-card-field-control:hover {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-credit-card-fieldset-root
  .hlx-credit-card-field-root[data-hlx-disabled='false']
  .hlx-credit-card-field-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-credit-card-fieldset-root > :first-of-type {
  grid-column: 1 / 3;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-control {
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  width: 100%;
  min-height: 36px;
  padding: 6px 12px;
  display: block;
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-credit-card-fieldset-root .hlx-credit-card-field-control {
    font-size: 17px;
  }
}

.hlx-credit-card-fieldset-root .hlx-credit-card-field-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-number-field-root {
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-number-field-root .hlx-number-field-label {
  color: var(--hlx-color-foreground-primary);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-number-field-root .hlx-number-field-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-number-field-root .hlx-number-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-number-field-root .hlx-number-field-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-number-field-root .hlx-number-field-help-text {
  color: var(--hlx-color-foreground-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-number-field-root .hlx-number-field-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-number-field-root .hlx-number-field-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--hlx-color-foreground-secondary);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-number-field-root .hlx-number-field-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-number-field-root .hlx-number-field-control-group {
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  color: var(--color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hlx-number-field-root .hlx-number-field-control-group {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-number-field-root .hlx-number-field-control-group {
    font-size: 17px;
  }
}

.hlx-number-field-root .hlx-number-field-currency-symbol {
  animation: 0.1s ease-in fade-in;
  position: absolute;
  left: 8px;
}

.hlx-number-field-root
  .hlx-number-field-currency-symbol
  + .hlx-number-field-control {
  padding-left: 22px;
}

.hlx-number-field-root .hlx-number-field-control {
  appearance: none;
  outline-offset: 2px;
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: none;
  outline: none;
  width: 100%;
  padding: 6px 12px;
  display: flex;
}

.hlx-number-field-root .hlx-number-field-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-number-field-root .hlx-number-field-control {
    font-size: 17px;
  }
}

.hlx-number-field-root .hlx-number-field-adornment {
  grid-area: 2 / 1 / 3 / 3;
  align-self: center;
  width: fit-content;
  display: flex;
}

.hlx-number-field-root
  .hlx-number-field-control.hlx-number-field-adornment-end {
  padding-right: calc(24px + var(--adornment-width));
}

.hlx-number-field-root
  .hlx-number-field-control.hlx-number-field-adornment-end
  + .hlx-number-field-adornment {
  justify-self: flex-end;
  margin-right: 12px;
}

.hlx-number-field-root[data-hlx-validation='invalid']
  .hlx-number-field-control-group {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-number-field-root[data-hlx-validation='invalid'] .hlx-number-field-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-number-field-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-number-field-root[data-hlx-disabled='true'] .hlx-number-field-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-number-field-root[data-hlx-disabled='true'] .hlx-number-field-label,
.hlx-number-field-root[data-hlx-disabled='true'] .hlx-number-field-help-text,
.hlx-number-field-root[data-hlx-disabled='true']
  .hlx-number-field-optionality-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-number-field-root[data-hlx-disabled='false']
  .hlx-number-field-control-group[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-number-field-root[data-hlx-disabled='false']
  .hlx-number-field-control-group:has([data-hlx-focused='true']) {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-number-field-root .hlx-number-field-control-group.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-text-area-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-primary);
  --secondary-descriptor-color: var(--hlx-color-foreground-secondary);
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-text-area-root[data-hlx-height='fill'] {
  grid-template-rows: auto 1fr;
  height: 100%;
}

.hlx-text-area-root .hlx-text-area-descriptors {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-columns: auto 1fr;
  gap: 4px 0;
  display: grid;
}

.hlx-text-area-root .hlx-text-area-descriptors .hlx-text-area-label {
  color: var(--primary-descriptor-color);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-text-area-root .hlx-text-area-descriptors .hlx-text-area-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-area-root
  .hlx-text-area-descriptors
  .hlx-text-area-instructional-text {
  color: var(--primary-descriptor-color);
  grid-area: 2 / 1 / 3 / 3;
}

.hlx-text-area-root
  .hlx-text-area-descriptors
  .hlx-text-area-instructional-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-text-area-root .hlx-text-area-descriptors .hlx-text-area-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--secondary-descriptor-color);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-text-area-root .hlx-text-area-descriptors .hlx-text-area-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-area-root .hlx-text-area-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-text-area-root .hlx-text-area-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-area-root .hlx-text-area-help-text {
  color: var(--secondary-descriptor-color);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-text-area-root .hlx-text-area-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-text-area-root .hlx-text-area-control {
  resize: none;
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  padding: 6px 12px;
  display: block;
}

.hlx-text-area-root .hlx-text-area-control ::placeholder {
  color: var(--placeholder-color);
}

.hlx-text-area-root .hlx-text-area-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-text-area-root .hlx-text-area-control {
    font-size: 17px;
  }
}

.hlx-text-area-root[data-hlx-validation='invalid'] .hlx-text-area-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-text-area-root[data-hlx-validation='invalid'] .hlx-text-area-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-text-area-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-disabled);
  --secondary-descriptor-color: var(--hlx-color-foreground-disabled);
}

.hlx-text-area-root[data-hlx-disabled='true'] .hlx-text-area-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-text-area-root[data-hlx-disabled='true'] .hlx-text-area-label,
.hlx-text-area-root[data-hlx-disabled='true'] .hlx-text-area-help-text,
.hlx-text-area-root[data-hlx-disabled='true'] .hlx-text-area-instructional-text,
.hlx-text-area-root[data-hlx-disabled='true'] .hlx-text-area-optionality-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-text-area-root[data-hlx-disabled='false']
  .hlx-text-area-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-text-area-root[data-hlx-disabled='false'] .hlx-text-area-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-text-area-root .hlx-text-area-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-rich-text-area-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  --primary-descriptor-color: var(--hlx-color-foreground-primary);
  --secondary-descriptor-color: var(--hlx-color-foreground-secondary);
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  display: grid;
}

.hlx-rich-text-area-root[data-hlx-height='fill'] {
  grid-template-rows: auto 1fr;
  height: 100%;
}

.hlx-rich-text-area-root[data-hlx-height='fill'] .hlx-rich-text-area-control {
  height: unset;
}

.hlx-rich-text-area-root .hlx-rich-text-area-label {
  color: var(--primary-descriptor-color);
  grid-area: 1 / 1 / 2 / 2;
  width: fit-content;
}

.hlx-rich-text-area-root .hlx-rich-text-area-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-rich-text-area-root .hlx-rich-text-area-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-help-text {
  color: var(--secondary-descriptor-color);
  grid-area: 5 / 1 / 6 / 3;
}

.hlx-rich-text-area-root .hlx-rich-text-area-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-instructional-text {
  color: var(--primary-descriptor-color);
  grid-area: 2 / 1 / 3 / 3;
}

.hlx-rich-text-area-root .hlx-rich-text-area-instructional-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--secondary-descriptor-color);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-rich-text-area-root .hlx-rich-text-area-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-editor {
  resize: none;
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  flex-direction: column-reverse;
  grid-area: 3 / 1 / 4 / 3;
  display: flex;
  overflow: hidden;
}

.hlx-rich-text-area-root .hlx-rich-text-area-editor {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-rich-text-area-root .hlx-rich-text-area-editor {
    font-size: 17px;
  }
}

.hlx-rich-text-area-root .hlx-rich-text-area-control {
  height: calc(var(--text-area-height) + 12px);
  outline: none;
  min-height: 80px;
  padding: 6px 12px;
  overflow: auto;
}

.hlx-rich-text-area-root .hlx-rich-text-area-control p {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-rich-text-area-root .hlx-rich-text-area-control p {
    font-size: 17px;
  }
}

.hlx-rich-text-area-root .hlx-rich-text-area-control em {
  font-style: italic;
}

.hlx-rich-text-area-root .hlx-rich-text-area-control strong {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-rich-text-area-root .hlx-rich-text-area-control strong {
    font-size: 17px;
  }
}

.hlx-rich-text-area-root .hlx-rich-text-area-control h2 {
  margin-top: var(--hlx-spacing-x6);
  margin-bottom: var(--hlx-spacing-x2);
}

.hlx-rich-text-area-root .hlx-rich-text-area-control h2:first-of-type {
  margin-top: 0;
}

.hlx-rich-text-area-root .hlx-rich-text-area-control h2 {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-rich-text-area-root .hlx-rich-text-area-toolbar {
  background-color: var(--hlx-color-background-secondary);
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  display: flex;
}

.hlx-rich-text-area-root .hlx-rich-text-area-toolbar button {
  aspect-ratio: 1;
  color: var(--hlx-color-foreground-primary);
  background-color: #0000;
  border: none;
  border-radius: 6px;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hlx-rich-text-area-root .hlx-rich-text-area-toolbar button.is-active {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-rich-text-area-root .hlx-rich-text-area-toolbar button.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-rich-text-area-root[data-hlx-validation='invalid']
  .hlx-rich-text-area-editor {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-rich-text-area-root[data-hlx-validation='invalid']
  .hlx-rich-text-area-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-rich-text-area-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-disabled);
  --secondary-descriptor-color: var(--hlx-color-foreground-disabled);
}

.hlx-rich-text-area-root[data-hlx-disabled='true'] .hlx-rich-text-area-label,
.hlx-rich-text-area-root[data-hlx-disabled='true']
  .hlx-rich-text-area-help-text,
.hlx-rich-text-area-root[data-hlx-disabled='true']
  .hlx-rich-text-area-optionality-text,
.hlx-rich-text-area-root[data-hlx-disabled='true']
  .hlx-rich-text-area-instructional-text {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-rich-text-area-root[data-hlx-disabled='true'] .hlx-rich-text-area-editor {
  background: var(--hlx-color-background-secondary);
}

.hlx-rich-text-area-root[data-hlx-disabled='true']
  .hlx-rich-text-area-toolbar-button {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-rich-text-area-root[data-hlx-disabled='false']
  .hlx-rich-text-area-editor[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-rich-text-area-root[data-hlx-disabled='false']
  .hlx-rich-text-area-editor.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-rich-text-area-root .hlx-rich-text-area-editor.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-rich-text-area-html-content p {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-rich-text-area-html-content p {
    font-size: 17px;
  }
}

.hlx-rich-text-area-html-content em {
  font-style: italic;
}

.hlx-rich-text-area-html-content strong {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-rich-text-area-html-content strong {
    font-size: 17px;
  }
}

.hlx-rich-text-area-html-content h2 {
  margin-top: var(--hlx-spacing-x6);
  margin-bottom: var(--hlx-spacing-x2);
}

.hlx-rich-text-area-html-content h2:first-of-type {
  margin-top: 0;
}

.hlx-rich-text-area-html-content h2 {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-group {
  gap: var(--hlx-spacing-x2);
  grid-template-columns: 1fr auto;
  align-items: start;
  display: grid;
}

.hlx-group .hlx-text-field-root:has(.hlx-text-field-label) + .hlx-button {
  margin-top: var(--hlx-spacing-x5);
}

.hlx-file-upload-root .hlx-file-upload-inner {
  border: 1px solid var(--hlx-color-border-primary);
  background: var(--hlx-color-background-primary);
  padding: var(--hlx-spacing-x2);
  border-radius: 4px;
}

.hlx-file-upload-root .hlx-file-upload-label {
  margin-bottom: var(--hlx-spacing-x1);
}

.hlx-file-upload-root .hlx-file-upload-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-file-upload-root .hlx-file-upload-dropzone {
  background: var(--hlx-color-background-secondary);
  padding-top: var(--hlx-spacing-x6);
  padding-bottom: var(--hlx-spacing-x6);
  border: 2px dashed #0000;
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hlx-file-upload-root .hlx-file-upload-dropzone:has(+ .hlx-file-upload-files) {
  margin-bottom: var(--hlx-spacing-x2);
}

.hlx-file-upload-root .hlx-file-upload-dropzone[data-drop-target] {
  border-color: var(--hlx-color-background-compliance);
  background: var(--hlx-color-background-tertiary);
}

.hlx-file-upload-root .hlx-file-upload-instructions {
  align-items: center;
  gap: var(--hlx-spacing-x2);
  display: flex;
}

.hlx-file-upload-root .hlx-file-upload-instructions .hlx-file-upload-trigger {
  text-decoration: underline;
}

.hlx-file-upload-root .hlx-file-upload-file-detail {
  align-items: center;
  gap: var(--hlx-spacing-x2);
  padding-left: var(--hlx-spacing-x2);
  padding-right: var(--hlx-spacing-x2);
  padding-top: var(--hlx-spacing-x2);
  padding-bottom: var(--hlx-spacing-x2);
  grid-template-columns: auto 1fr auto;
  display: grid;
}

.hlx-file-upload-root .hlx-file-upload-file-meta {
  flex-direction: column;
  display: flex;
}

.hlx-filter-group {
  gap: var(--hlx-spacing-x3);
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.hlx-filter-group[data-variant='exposed'] .hlx-filter-button-all {
  display: none;
}

.hlx-filter-group[data-variant='condensed']
  .hlx-filter-button:not(.hlx-filter-button-all) {
  display: none;
}

.hlx-filter-group[data-variant='hybrid']
  .hlx-filter-button:has(+ .hlx-filter-button-all):after {
  border-right: 1px solid var(--hlx-color-border-primary);
}

.hlx-filter-group .hlx-filter-separator {
  background-color: var(--hlx-color-border-primary);
  display: flex;
  position: relative;
}

.hlx-filter-group .hlx-filter-separator[aria-orientation='vertical'] {
  margin: 0 var(--hlx-spacing-x2);
  align-self: stretch;
  min-width: 1px;
  min-height: 100%;
}

.hlx-filter-dialog {
  outline: none;
  min-width: 240px;
}

.hlx-filter-dialog .hlx-filter-dialog-content {
  padding: 15px var(--hlx-spacing-x4);
}

.hlx-filter-dialog .hlx-filter-dialog-content[data-variant='switch'] {
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  display: flex;
}

.hlx-filter-dialog
  .hlx-filter-dialog-content[data-variant='switch']
  .hlx-switch-root {
  justify-content: space-between;
}

.hlx-filter-dialog .hlx-filter-dialog-content[data-variant='date'] {
  padding: 0;
}

.hlx-filter-dialog .hlx-filter-dialog-content:not([data-variant='date']) {
  max-height: 370px;
  overflow-y: auto;
}

.hlx-filter-dialog .hlx-radio-group-controls,
.hlx-filter-dialog .hlx-checkbox-group-controls {
  gap: 10px;
}

.hlx-filter-dialog .hlx-filter-dialog-footer {
  gap: var(--hlx-spacing-x4);
  padding: var(--hlx-spacing-x4);
  border-top: 1px solid var(--hlx-color-border-primary);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.hlx-filter-button {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap: var(--hlx-spacing-x2);
  --border-color: var(--hlx-color-border-primary);
  --bg-color: var(--hlx-color-background-primary);
  --text-color: var(--hlx-color-foreground-primary);
  background-color: var(--bg-color);
  border: none;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  outline: none;
  outline: 0px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  border-radius: 4px;
  padding: 0;
  display: flex;
}

.hlx-filter-button[data-hlx-size='medium'] {
  padding: 6px 12px;
}

.hlx-filter-button[data-hlx-size='medium'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-filter-button[data-hlx-size='medium'] {
    padding: 10px 15px;
    font-size: 15px;
  }
}

.hlx-filter-button[data-hlx-size='large'] {
  padding: 6px 16px;
}

.hlx-filter-button[data-hlx-size='large'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-filter-button[data-hlx-size='large'] {
    padding: 10px 15px;
    font-size: 17px;
  }
}

.hlx-filter-button[data-focus-visible] {
  outline-width: 2px;
}

.hlx-filter-button[data-hovered],
.hlx-filter-button[data-focused] {
  cursor: pointer;
  --bg-color: var(--hlx-color-background-tertiary);
}

.hlx-filter-button[data-pressed] {
  --bg-color: var(--hlx-color-background-quartenary);
}

.hlx-filter-button[data-selected='true'] {
  --bg-color: var(--hlx-color-background-selected);
  --border-color: var(--hlx-color-border-focus);
}

.hlx-filter-button[data-selected][data-hovered],
.hlx-filter-button[data-selected][data-focused] {
  --bg-color: var(--hlx-color-background-selected-hover);
}

.hlx-filter-button[data-selected][data-pressed] {
  --bg-color: var(--hlx-color-background-selected-active);
}

.hlx-filter-button[data-disabled] {
  --border-color: var(--hlx-color-border-disabled);
  --text-color: var(--hlx-color-foreground-disabled);
  --bg-color: var(--hlx-color-background-tertiary);
}

.hlx-filter-button[data-disabled][data-selected] {
  --bg-color: var(--hlx-color-background-selected);
  --text-color: #0844284d;
  --border-color: #08442826;
}

.hlx-filter-button-root {
  align-items: center;
  display: flex;
  position: relative;
}

.hlx-filter-button-root .hlx-filter-button[data-selected='true'] {
  padding-right: 40px;
}

.hlx-filter-button-root .hlx-filter-button-clear {
  all: unset;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  right: 12px;
}

.hlx-filter-button-root .hlx-filter-button-clear:hover {
  cursor: pointer;
  background-color: #a8ead1;
}

.hlx-filter-button-content {
  align-items: center;
  gap: var(--hlx-spacing-x2);
  display: flex;
}

.hlx-filter-button-all .hlx-filter-button-count {
  display: none;
}

.hlx-filter-button-all[data-selected='true'] .hlx-filter-button-count {
  background-color: var(--hlx-color-foreground-compliance);
  color: var(--hlx-color-background-selected);
  border-radius: 20px;
  gap: 4px;
  padding: 1px 10px;
  display: flex;
}

.hlx-filter-button-all[data-selected='true'] .hlx-filter-button-count {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-filter-button-all[data-selected='true'] .hlx-filter-button-count {
    font-size: 15px;
  }
}

.hlx-halfsheet {
  background: var(--hlx-color-background-primary);
  z-index: var(--hlx-layer-popover);
  isolation: isolate;
  box-shadow: var(--hlx-elevation-medium);
  border: 1px solid var(--hlx-color-border-primary);
  will-change: transform;
  padding: var(--hlx-spacing-x5);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100vw;
  height: auto;
  min-height: 50vh;
  max-height: 70vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.hlx-halfsheet .hlx-halfsheet-dialog {
  outline: none;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  display: flex;
}

.hlx-halfsheet .hlx-halfsheet-footer {
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  margin-top: auto;
  display: flex;
}

.hlx-halfsheet .hlx-halfsheet-header {
  color: #0000;
  padding: var(--hlx-spacing-x2);
  align-items: center;
  gap: var(--hlx-spacing-x1);
  justify-content: flex-end;
  display: flex;
  position: sticky;
  top: 0;
}

@media screen and (min-width: 768px) {
  .hlx-halfsheet .hlx-halfsheet-header {
    border-bottom: 1px solid var(--hlx-color-border-primary);
    grid-template-columns: 1fr 1fr auto;
  }
}

.hlx-halfsheet .hlx-halfsheet-header > .hlx-icon-button:first-child {
  grid-area: 1 / 1;
}

@media screen and (min-width: 768px) {
  .hlx-halfsheet .hlx-halfsheet-header > .hlx-icon-button:first-child {
    display: none;
  }
}

.hlx-halfsheet
  .hlx-halfsheet-header
  > .hlx-halfsheet-heading
  + .hlx-icon-button {
  grid-area: 1 / 1;
}

.hlx-halfsheet .hlx-halfsheet-header > .hlx-icon-button svg {
  width: 28px;
  height: 28px;
}

@media screen and (min-width: 768px) {
  .hlx-halfsheet .hlx-halfsheet-header > .hlx-icon-button svg {
    width: 20px;
    height: 20px;
  }
}

.hlx-page-section {
  --section-gap: 32px;
  padding: var(--section-gap) 16px;
  width: min(100%, 927px);
  margin: 0 auto;
}

.hlx-page-section ~ .hlx-page-section,
.hlx-page-section ~ * .hlx-page-section {
  border-top: 1px solid var(--hlx-color-border-primary);
}

.hlx-page-section[data-hlx-layout='grid.one-column'] {
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 20px;
  display: grid;
}

.hlx-page-section[data-hlx-layout='grid.one-column'] .hlx-page-section-subtext {
  max-width: 65ch;
}

.hlx-page-section[data-hlx-layout='grid.two-column'] {
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .hlx-page-section[data-hlx-layout='grid.two-column'] {
    grid-template-columns: 1fr 1fr;
  }

  .hlx-page-section[data-hlx-layout='grid.two-column']
    .hlx-page-section-subtext {
    max-width: 35ch;
  }
}

.hlx-page-section-subtext {
  margin-top: var(--hlx-spacing-x2);
}

.hlx-empty-state {
  padding: var(--hlx-spacing-x8) var(--hlx-spacing-x5);
  text-align: center;
  background-color: var(--hlx-color-background-secondary);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.hlx-tab {
  padding: var(--hlx-spacing-x3) var(--hlx-spacing-x4) var(--hlx-spacing-x3)
    var(--hlx-spacing-x4);
  white-space: nowrap;
  --bg-hover: #f2f2f2;
  display: flex;
}

.hlx-tab.focus-ring {
  outline-offset: 2px;
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

.hlx-tab[data-hlx-hovered='true'] {
  cursor: pointer;
  background-color: var(--bg-hover);
  color: var(--hlx-color-foreground-secondary);
}

.hlx-tab[data-hlx-pressed='true'] {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-tab[data-hlx-selected='true'] {
  border-bottom: 2px solid var(--hlx-color-foreground-primary);
  color: var(--hlx-color-foreground-primary);
  outline: none;
}

.hlx-tab[data-hlx-selected='true'] {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-tab[data-hlx-selected='true'] {
    font-size: 17px;
  }
}

.hlx-tab[data-hlx-disabled='true'] {
  --border-color: var(--hlx-color-border-disabled);
  color: var(--hlx-color-foreground-disabled);
  text-decoration-color: var(--hlx-color-background-disabled);
}

.hlx-tab[data-hlx-disabled='true'][data-hlx-selected='true'] {
  border-bottom: 2px solid var(--hlx-color-background-disabled);
}

.hlx-tab-panel.focus-ring {
  outline-offset: 2px;
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

.hlx-tab-list {
  color: var(--hlx-color-foreground-secondary);
  flex-direction: row;
  display: flex;
  overflow-x: hidden;
}

.hlx-tab-list {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-tab-list {
    font-size: 17px;
  }
}

.hlx-tab-list-wrapper {
  border-bottom: 1px solid var(--hlx-color-border-primary);
  flex: auto;
  min-width: 0;
  display: flex;
  position: relative;
}

.hlx-tab-left-button-wrapper {
  box-shadow: 24px 0px 24px 0px var(--hlx-color-background-primary);
  background-color: var(--hlx-color-background-primary);
  align-items: center;
  height: calc(100% - 1px);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.hlx-tab-right-button-wrapper {
  box-shadow: -24px 0px 24px 0px var(--hlx-color-background-primary);
  background-color: var(--hlx-color-background-primary);
  align-items: center;
  height: calc(100% - 1px);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.hlx-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.hlx-pagination .hlx-pagination-item {
  align-items: center;
  display: flex;
}

.hlx-pagination .hlx-pagination-button {
  color: var(--hlx-color-foreground-primary);
  background-color: #0000;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  margin: 1px;
}

.hlx-pagination .hlx-pagination-button {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-pagination .hlx-pagination-button {
    font-size: 17px;
  }
}

.hlx-pagination .hlx-pagination-button[data-hlx-hovered='true'] {
  cursor: pointer;
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-pagination .hlx-pagination-button[data-hlx-active='true'] {
  --disabled-bg-color: transparent;
  --hover-bg-color: var(--hlx-color-background-tertiary);
  --active-bg-color: var(--hlx-color-background-quartenary);
  --active-text-color: var(--hlx-color-foreground-primary);
  background-color: var(--active-bg-color);
}

.hlx-pagination .hlx-pagination-button.focus-ring {
  outline-offset: 2px;
  z-index: 1;
  outline: 2px solid var(--hlx-color-border-focus) !important;
}

.hlx-pagination .hlx-pagination-ellipsis {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.hlx-navigation[data-hlx-layout='default'] {
  border-right: 1px solid var(--hlx-color-border-primary);
  width: 238px;
}

.hlx-navigation[data-hlx-layout='full-width'] {
  border: none;
  width: auto;
}

.hlx-navigation .hlx-navigation-list {
  margin: 0;
  padding: 8px 0 0;
  list-style: none;
}

.hlx-navigation .hlx-navigation-divider {
  border-top: 1px solid var(--hlx-color-border-primary);
  margin: 8px 0;
}

.hlx-navigation .hlx-navigation-link {
  --background-color: transparent;
  --color: var(--hlx-color-foreground-primary);
  --bg-active: var(--hlx-color-background-tertiary);
  --bg-hover: #f2f2f2;
  background-color: var(--background-color);
  color: var(--color);
  outline: none;
  align-items: center;
  gap: 12px;
  padding: 11px 20px;
  text-decoration: none;
  display: flex;
}

.hlx-navigation .hlx-navigation-link {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-navigation .hlx-navigation-link {
    font-size: 17px;
  }
}

.hlx-navigation .hlx-navigation-link[data-hlx-hovered='true'] {
  --background-color: var(--bg-hover);
}

.hlx-navigation .hlx-navigation-link.focused {
  --background-color: var(--bg-active);
}

.hlx-navigation
  .hlx-navigation-link.focused
  .hlx-navigation-link-icon
  > svg:first-of-type {
  display: none;
}

.hlx-navigation
  .hlx-navigation-link.focused
  .hlx-navigation-link-icon
  > svg:last-child {
  display: block;
}

.hlx-navigation .hlx-navigation-link.focus-ring {
  --background-color: var(--bg-active);
  outline: 2px solid var(--hlx-color-border-focus);
}

.hlx-navigation .hlx-navigation-link[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
}

.hlx-navigation .hlx-navigation-link .hlx-navigation-link-icon {
  color: var(--color);
  display: flex;
}

.hlx-navigation
  .hlx-navigation-link
  .hlx-navigation-link-icon
  > svg:first-of-type {
  display: block;
}

.hlx-navigation
  .hlx-navigation-link
  .hlx-navigation-link-icon
  > svg:last-child {
  display: none;
}

.hlx-navigation .hlx-navigation-link[aria-current='page'],
.hlx-navigation .hlx-navigation-link.active,
.hlx-navigation
  .hlx-navigation-link[data-hlx-pressed='true']:not(
    [data-hlx-disabled='true']
  ) {
  --background-color: var(--bg-active);
}

:is(
    .hlx-navigation .hlx-navigation-link[aria-current='page'],
    .hlx-navigation .hlx-navigation-link.active,
    .hlx-navigation
      .hlx-navigation-link[data-hlx-pressed='true']:not(
        [data-hlx-disabled='true']
      )
  ) {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  :is(
      .hlx-navigation .hlx-navigation-link[aria-current='page'],
      .hlx-navigation .hlx-navigation-link.active,
      .hlx-navigation
        .hlx-navigation-link[data-hlx-pressed='true']:not(
          [data-hlx-disabled='true']
        )
    ) {
    font-size: 17px;
  }
}

:is(
    .hlx-navigation .hlx-navigation-link[aria-current='page'],
    .hlx-navigation .hlx-navigation-link.active,
    .hlx-navigation
      .hlx-navigation-link[data-hlx-pressed='true']:not(
        [data-hlx-disabled='true']
      )
  )
  .hlx-navigation-link-icon
  > svg:first-of-type {
  display: none;
}

:is(
    .hlx-navigation .hlx-navigation-link[aria-current='page'],
    .hlx-navigation .hlx-navigation-link.active,
    .hlx-navigation
      .hlx-navigation-link[data-hlx-pressed='true']:not(
        [data-hlx-disabled='true']
      )
  )
  .hlx-navigation-link-icon
  > svg:last-child {
  display: block;
}

:is(
    .hlx-navigation .hlx-navigation-link[aria-current='page'],
    .hlx-navigation .hlx-navigation-link.active,
    .hlx-navigation
      .hlx-navigation-link[data-hlx-pressed='true']:not(
        [data-hlx-disabled='true']
      )
  )
  .hlx-badge[data-hlx-variant='neutral'] {
  background-color: var(--hlx-color-background-quartenary);
}

.hlx-select-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  grid-row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-primary);
  --secondary-descriptor-color: var(--hlx-color-foreground-secondary);
  grid-template-rows: auto auto;
  display: grid;
  position: relative;
}

.hlx-select-descriptors {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-columns: auto 1fr;
  gap: 4px 0;
  display: grid;
}

.hlx-select-descriptors .hlx-select-label {
  color: var(--primary-descriptor-color);
  white-space: nowrap;
  grid-area: 1 / 1 / 2 / 2;
  width: max-content;
}

.hlx-select-descriptors .hlx-select-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-select-descriptors .hlx-select-instructional-text {
  color: var(--primary-descriptor-color);
  grid-area: 2 / 1 / 3 / 3;
}

.hlx-select-descriptors .hlx-select-instructional-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-select-descriptors .hlx-select-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--secondary-descriptor-color);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-select-descriptors .hlx-select-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-select-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-select-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-select-help-text {
  color: var(--secondary-descriptor-color);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-select-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-select-control {
  justify-content: space-between;
  align-items: center;
  gap: var(--hlx-spacing-x2);
  appearance: none;
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  --background-color: var(--hlx-color-background-primary);
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  min-height: 36px;
  padding: 5px 8px;
  display: flex;
}

.hlx-select-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-select-control {
    font-size: 17px;
  }
}

.hlx-select-control.placeholder {
  padding: 6px 8px;
}

.hlx-select-control span {
  color: inherit;
}

.hlx-select-control .hlx-select-control-chevron {
  margin-left: auto;
}

.hlx-select-control.placeholder span {
  color: var(--placeholder-color);
}

.hlx-select .hlx-chip-group-root {
  color: var(--color);
  grid-area: 2 / 1 / 3 / 3;
  width: fit-content;
  padding: 5px 36px 5px 8px;
  display: flex;
}

.hlx-select .hlx-chip-group-root span {
  color: inherit;
}

.hlx-combobox .hlx-chip-group-root .hlx-chip-group-end-slot-placeholder,
.hlx-select .hlx-chip-group-root .hlx-chip-group-end-slot-placeholder {
  display: flex;
}

.hlx-select-root[data-hlx-validation='invalid'] .hlx-select-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-select-root[data-hlx-validation='invalid'] .hlx-select-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-select-root[data-hlx-disabled='true'] {
  --color: var(--hlx-color-foreground-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-disabled);
  --secondary-descriptor-color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
}

.hlx-select-root[data-hlx-disabled='true'] .hlx-select-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-select-root[data-hlx-disabled='false']
  .hlx-select-control[data-hlx-hovered='true'] {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-select-root[data-hlx-disabled='false'] .hlx-select-control.focused,
.hlx-select-root[data-hlx-disabled='false']
  .hlx-select-control[aria-expanded='true'] {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-select-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-listbox {
  outline: none;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  overflow-y: auto;
}

.hlx-listbox .hlx-listbox-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hlx-listbox .hlx-listbox-section .hlx-listbox-separator {
  border-color: var(--hlx-color-border-primary);
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.hlx-listbox .hlx-listbox-section .hlx-listbox-section-heading {
  color: var(--hlx-color-foreground-primary);
  padding: 0 16px 12px;
}

.hlx-listbox .hlx-listbox-section .hlx-listbox-section-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-listbox .hlx-listbox-option {
  --padding-y: 10px;
  --padding-l: 20px;
  --padding-r: 20px;
  gap: var(--hlx-spacing-x4);
  padding: var(--padding-y) var(--padding-r) var(--padding-y) var(--padding-l);
  cursor: pointer;
  color: var(--hlx-color-foreground-primary);
  outline-offset: 0px;
  outline: none;
  outline: 2px solid var(--outline-color);
  display: flex;
}

.hlx-listbox .hlx-listbox-option {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-listbox .hlx-listbox-option {
    font-size: 17px;
  }
}

@media screen and (min-width: 1044px) {
  .hlx-listbox .hlx-listbox-option {
    --padding-y: 5px;
    --padding-l: 16px;
    --padding-r: 16px;
    gap: var(--hlx-spacing-x2);
  }
}

.hlx-listbox
  .hlx-listbox-option[data-selection-mode='single'][data-selected='false'] {
  --padding-l: 60px;
}

@media screen and (min-width: 1044px) {
  .hlx-listbox
    .hlx-listbox-option[data-selection-mode='single'][data-selected='false'] {
    --padding-l: 44px;
  }
}

@media screen and (min-width: 1044px) {
  .hlx-listbox
    .hlx-listbox-option[data-selection-mode='multiple'][data-selected='false'] {
    --padding-l: 44px;
  }
}

.hlx-listbox .hlx-listbox-option.focus-ring {
  padding: var(--padding-y) calc(var(--padding-r) - 2px) var(--padding-y)
    calc(var(--padding-l) - 2px);
  --outline-color: var(--hlx-color-border-focus);
  margin: 0 2px;
}

.hlx-listbox .hlx-listbox-option[data-focused='true'] {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-listbox .hlx-listbox-option[data-disabled='true'] {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-listbox .hlx-listbox-option.no-results {
  justify-content: center;
  padding: 6px 16px;
}

.hlx-popover {
  isolation: isolate;
  border: 1px solid var(--hlx-color-border-primary);
  background: var(--hlx-color-background-primary);
  box-shadow: var(--hlx-elevation-light);
  z-index: var(--hlx-layer-popover);
  border-radius: 4px;
  outline: none;
  flex-direction: column;
  display: flex;
}

.hlx-combobox.hlx-combobox-root {
  scroll-margin-top: calc(var(--hw-header-height) + var(--hlx-spacing-x4));
  row-gap: var(--hlx-spacing-x1);
  --color: var(--hlx-color-foreground-primary);
  --border-color: var(--hlx-color-border-primary);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-primary);
  --secondary-descriptor-color: var(--hlx-color-foreground-secondary);
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  display: grid;
  position: relative;
}

.hlx-combobox .hlx-combobox-descriptors {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-columns: auto 1fr;
  gap: 4px 0;
  display: grid;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-label {
  color: var(--primary-descriptor-color);
  white-space: nowrap;
  grid-area: 1 / 1 / 2 / 2;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-optionality-text {
  margin-left: var(--hlx-spacing-x2);
  color: var(--secondary-descriptor-color);
  grid-area: 1 / 2 / 2 / 3;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-optionality-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-instructional-text {
  color: var(--primary-descriptor-color);
  grid-area: 2 / 1 / 3 / 3;
}

.hlx-combobox .hlx-combobox-descriptors .hlx-combobox-instructional-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-combobox .hlx-combobox-error {
  color: var(--hlx-color-foreground-danger-secondary);
  grid-area: 3 / 1 / 4 / 3;
}

.hlx-combobox .hlx-combobox-error {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-combobox .hlx-combobox-help-text {
  color: var(--secondary-descriptor-color);
  grid-area: 4 / 1 / 5 / 3;
}

.hlx-combobox .hlx-combobox-help-text {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-combobox .hlx-combobox-control {
  appearance: none;
  padding: 5px 12px;
  padding-right: calc(var(--combobox-actions-width) * 1px);
  outline-offset: 2px;
  --outline-color: var(--hlx-color-border-focus);
  color: var(--color);
  background-color: var(--hlx-color-background-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  min-height: 36px;
}

.hlx-combobox .hlx-combobox-control {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-combobox .hlx-combobox-control {
    font-size: 17px;
  }
}

.hlx-combobox .hlx-combobox-mobile-trigger {
  margin: 6px 12px;
  margin-right: calc(var(--combobox-actions-width) * 1px);
  color: var(--color);
  white-space: nowrap;
  text-align: left;
  background-color: #0000;
  border: none;
  outline: none;
  grid-area: 2 / 1 / 3 / 3;
  overflow: hidden;
}

.hlx-combobox .hlx-combobox-mobile-trigger {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-combobox .hlx-combobox-mobile-trigger {
    font-size: 17px;
  }
}

.hlx-combobox .hlx-combobox-mobile-trigger[data-placeholder='true'] {
  color: var(--placeholder-color);
}

.hlx-combobox .hlx-combobox-input {
  box-shadow: none;
  color: var(--hlx-color-foreground-primary);
  background-color: #0000;
  border: none;
  outline: none;
  width: 100%;
}

.hlx-combobox .hlx-combobox-input ::placeholder {
  color: var(--placeholder-color);
}

.hlx-combobox .hlx-combobox-input {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-combobox .hlx-combobox-input {
    font-size: 17px;
  }
}

.hlx-combobox .hlx-combobox-actions {
  grid-area: 2 / 2 / 3 / 3;
  justify-self: flex-end;
  align-items: stretch;
  display: flex;
}

.hlx-combobox .hlx-combobox-actions button {
  color: var(--color);
  padding: 0 var(--hlx-spacing-x2);
  background-color: #0000;
  border: none;
  outline: none;
  place-items: center;
  display: grid;
}

.hlx-combobox .hlx-chip-group-root {
  display: flex;
}

.hlx-combobox .hlx-combobox-control span {
  color: inherit;
}

.hlx-combobox .hlx-popover {
  grid-row: 3 / 5;
}

.hlx-combobox[data-hlx-validation='invalid'] .hlx-combobox-control {
  --border-color: var(--hlx-color-border-danger);
}

.hlx-combobox[data-hlx-validation='invalid'] .hlx-combobox-error {
  color: var(--hlx-color-foreground-danger-secondary);
}

.hlx-combobox[data-disabled-state='disabled'] {
  --color: var(--hlx-color-foreground-disabled);
  --border-color: var(--hlx-color-border-disabled);
  --placeholder-color: var(--hlx-color-foreground-disabled);
  --primary-descriptor-color: var(--hlx-color-foreground-disabled);
  --secondary-descriptor-color: var(--hlx-color-foreground-disabled);
}

.hlx-combobox[data-disabled-state='disabled'] .hlx-combobox-control {
  background-color: var(--hlx-color-background-secondary);
}

.hlx-combobox[data-disabled-state='disabled'] .hlx-combobox-input,
.hlx-combobox[data-disabled-state='disabled'] .hlx-combobox-input::placeholder {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-combobox[data-disabled-state='enabled']:where([data-hlx-hovered='true'])
  .hlx-combobox-control {
  --border-color: var(--hlx-color-border-secondary);
}

.hlx-combobox .hlx-combobox-control.focus-ring {
  outline: 2px solid var(--outline-color);
}

.hlx-combobox .hlx-combobox-control.focused {
  --border-color: var(--hlx-color-border-tertiary);
}

.hlx-tray-root {
  height: var(--height);
  z-index: 2000;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
}

.hlx-tray-root .hlx-tray-panel {
  background: var(--hlx-color-background-primary);
  width: 100%;
  height: 100%;
  transition:
    opacity 0.2s cubic-bezier(0.5, 0, 1, 1),
    transform 0.2s cubic-bezier(0.5, 0, 1, 1);
  bottom: 0;
  transform: translateY(100%);
}

.hlx-tray-root .hlx-tray-panel[data-open='true'] {
  transform: translateY(0%);
}

.hlx-panel {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.hlx-panel .hlx-panel-header {
  transform-origin: top;
  padding: var(--hlx-spacing-x5) var(--hlx-spacing-x12);
  justify-content: center;
  align-items: center;
  display: flex;
}

.hlx-panel .hlx-panel-header .hlx-icon-button {
  width: var(--hlx-spacing-x12);
  position: absolute;
  left: 0;
}

.hlx-panel .hlx-panel-filter {
  padding: 0 var(--hlx-spacing-x4);
  align-items: center;
  gap: 12px;
  padding-bottom: 0;
  display: flex;
}

.hlx-panel .hlx-panel-filter[data-active='true'] {
  padding-left: var(--hlx-spacing-x4);
  padding-top: var(--hlx-spacing-x4);
}

.hlx-panel .hlx-panel-filter .hlx-search-field-root {
  width: 100%;
  position: relative;
}

.hlx-panel .hlx-panel-title {
  color: var(--hlx-color-foreground-primary);
}

.hlx-panel .hlx-panel-title {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-panel .hlx-panel-title {
    font-size: 17px;
  }
}

.hlx-panel .hlx-panel-content {
  flex: 1;
  overflow-y: auto;
}

.hlx-panel .hlx-panel-content .hlx-listbox {
  padding-top: 14px;
}

.hlx-panel .hlx-panel-footer {
  border-top: 1px solid var(--hlx-color-border-primary);
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  display: flex;
}

.hlx-menu {
  outline: none;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  overflow: auto;
}

.hlx-menu .hlx-menu-section-separator {
  border-color: var(--hlx-color-border-primary);
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.hlx-menu .hlx-menu-section-heading {
  color: var(--hlx-color-foreground-primary);
  padding: 0 16px 12px;
}

.hlx-menu .hlx-menu-section-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-menu .hlx-menu-section {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hlx-menu .hlx-menu-item {
  --padding-inline: 16px;
  --padding-block: 6px;
  padding-left: var(--padding-inline);
  padding-right: var(--padding-inline);
  padding-top: var(--padding-block);
  padding-bottom: var(--padding-block);
  padding-inline: var(--padding-inline);
  padding-block: var(--padding-block);
  cursor: pointer;
  color: var(--hlx-color-foreground-primary);
  outline: none;
  align-items: center;
  gap: 16px;
  display: flex;
}

.hlx-menu .hlx-menu-item {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-menu .hlx-menu-item {
    font-size: 17px;
  }
}

.hlx-menu .hlx-menu-item.hlx-menu-item-link {
  padding: 0;
}

.hlx-menu .hlx-menu-item.hlx-menu-item-link > * {
  padding-left: var(--padding-inline);
  padding-right: var(--padding-inline);
  padding-top: var(--padding-block);
  padding-bottom: var(--padding-block);
  padding-inline: var(--padding-inline);
  padding-block: var(--padding-block);
}

.hlx-menu .hlx-menu-item[data-selected='true'] {
  padding-left: 16px;
}

.hlx-menu .hlx-menu-item.focus-ring {
  --padding-inline: 14px;
  background-color: var(--hlx-color-background-tertiary);
  --outline-color: var(--hlx-color-border-focus);
  outline-offset: 0px;
  outline: 2px solid var(--outline-color);
  margin: 0 2px;
}

.hlx-menu .hlx-menu-item[data-hovered='true'] {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-menu .hlx-menu-item[data-disabled='true'] {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-tree {
  gap: var(--hlx-spacing-x2);
  flex-direction: column;
  display: flex;
}

.hlx-tree .hlx-tree-item-group {
  gap: var(--hlx-spacing-x2);
  margin-left: calc((var(--level) - 1) * 29px);
  cursor: default;
  display: flex;
}

.hlx-tree .hlx-tree-item-group .hlx-tree-item-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-tree .hlx-tree-item-group .hlx-tree-item-label {
    font-size: 17px;
  }
}

.hlx-tree
  .hlx-tree-item-group:has(+ .hlx-tree-item-description)
  .hlx-tree-item-label {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-tree
    .hlx-tree-item-group:has(+ .hlx-tree-item-description)
    .hlx-tree-item-label {
    font-size: 17px;
  }
}

.hlx-tree .hlx-tree-item-description {
  padding-left: calc(20px + var(--hlx-spacing-x2));
}

.hlx-tree .hlx-tree-item-description {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-tree .hlx-tree-item-description {
    font-size: 17px;
  }
}

.hlx-tree .hlx-tree-item {
  color: var(--hlx-color-foreground-primary);
  outline: none;
}

.hlx-tree
  .hlx-tree-item.hovered:has(.hlx-tree-expand-toggle.hovered)
  .hlx-checkbox:not([data-selected='true']):not([data-indeterminate='true'])
  .hlx-checkbox-control {
  color: var(--hlx-color-background-disabled);
}

.hlx-tree .hlx-tree-item.disabled {
  color: var(--hlx-color-foreground-disabled);
}

.hlx-tree .hlx-tree-expand-toggle {
  cursor: pointer;
  appearance: none;
  outline: 0px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  display: flex;
}

.hlx-tree .hlx-tree-expand-toggle.hovered {
  background-color: var(--hlx-color-background-tertiary);
}

.hlx-tree .hlx-tree-expand-toggle.focus-visible {
  outline-width: 2px;
}

:where([class^='hlx-typography-'], [class*=' hlx-typography-']) {
  color: var(--hlx-color-foreground-primary);
}

.hlx-typography-headline-h1 {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-20);
  line-height: var(--hlx-typography-line-height-base-17);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

.hlx-typography-headline-h2 {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-18);
  line-height: var(--hlx-typography-line-height-base-14);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

@media screen and (max-width: 768px) {
  .hlx-typography-headline-h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.hlx-typography-headline-h3 {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-15);
  line-height: var(--hlx-typography-line-height-base-10);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

.hlx-typography-headline-h4 {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-14);
  line-height: var(--hlx-typography-line-height-base-9);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

.hlx-typography-content-page-title {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-13);
  line-height: var(--hlx-typography-line-height-base-10);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.02em;
}

.hlx-typography-content-section-title {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-11);
  line-height: var(--hlx-typography-line-height-base-8);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: -0.01em;
}

.hlx-typography-content-section-title-medium {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-11);
  line-height: var(--hlx-typography-line-height-base-8);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.01em;
}

.hlx-typography-content-body-large {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-typography-content-body-large-medium {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-typography-content-body {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-content-body {
    font-size: 17px;
  }
}

.hlx-typography-content-body-medium {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-content-body-medium {
    font-size: 17px;
  }
}

.hlx-typography-content-body-small {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-content-body-small {
    font-size: 15px;
  }
}

.hlx-typography-content-body-small-medium {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-content-body-small-medium {
    font-size: 15px;
  }
}

.hlx-typography-content-caption {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-typography-content-caption-medium {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-typography-body {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-7);
  line-height: var(--hlx-typography-line-height-base-6);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-body {
    font-size: 17px;
  }
}

.hlx-typography-subbody {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-5);
  line-height: var(--hlx-typography-line-height-base-4);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

@media screen and (max-width: 768px) {
  .hlx-typography-subbody {
    font-size: 15px;
  }
}

.hlx-typography-caption {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-4);
  line-height: var(--hlx-typography-line-height-base-3);
  font-weight: var(--hlx-typography-font-weight-content-regular);
  letter-spacing: normal;
}

.hlx-typography-brand-heading {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-15);
  line-height: var(--hlx-typography-line-height-base-10);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

.hlx-typography-brand-section {
  font-family: var(--hlx-typography-font-family-headline);
  font-size: var(--hlx-typography-font-size-base-14);
  line-height: var(--hlx-typography-line-height-base-9);
  font-weight: var(--hlx-typography-font-weight-headline-regular);
  letter-spacing: -0.02em;
}

.hlx-typography-section-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-11);
  line-height: var(--hlx-typography-line-height-base-8);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.01em;
}

.hlx-typography-list-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-9);
  line-height: var(--hlx-typography-line-height-base-7);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: normal;
}

.hlx-typography-brand-content {
  font-family: var(--hlx-typography-font-family-headline), serif;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.hlx-typography-page-heading {
  font-family: var(--hlx-typography-font-family-content);
  font-size: var(--hlx-typography-font-size-base-13);
  line-height: var(--hlx-typography-line-height-base-10);
  font-weight: var(--hlx-typography-font-weight-content-medium);
  letter-spacing: -0.02em;
}

:where(
    .hlx-typography-content-body strong,
    .hlx-typography-content-body-small strong,
    .hlx-typography-content-body-large strong,
    .hlx-typography-content-caption strong,
    .hlx-typography-content-section-title strong
  ) {
  font-weight: var(--hlx-typography-font-weight-content-medium);
}
